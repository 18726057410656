import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../../app.settings';
import { Observable, throwError, from, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Headers, RequestOptions, Request, RequestMethod } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class SuperadmindashboardService {

  constructor(private http: HttpClient) { }
  
   public getSuperadminDashboardData(): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.SUPERADMINDASHBOARD)
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(this.errorHandler)
      );
   }
    errorHandler(error: any) {
    console.log('Error--->', error);
    return throwError(error);
  }
}
