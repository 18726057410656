export const environment = {
  production: false,
  APIURLS: {
    GETAUTHUSER: 'https://api.covermyday.com/auth/',
    USERSIGNUP: 'https://api.covermyday.com/auth/signup',
    USERSIGNIN: 'https://api.covermyday.com/auth/signin',
    GENERATECODE: 'https://api.covermyday.com/auth/code',
    VERIFYCODE: 'https://api.covermyday.com/auth/verifycode',
    FORGOTPASSWORD: 'https://api.covermyday.com/auth/forgotpassword',
    CREATEPHARMACYUSER: 'https://api.covermyday.com/pharmacy/pharmacyUser/create',
    CREATEPHARMACY: 'https://api.covermyday.com/pharmacy/create',
    LISTPHARMACY: 'https://api.covermyday.com/pharmacy/pharmacyUser/auth/',
    UPDATEPHARMACY: 'https://api.covermyday.com/pharmacy/',
    GETPHARMACYDETAILSBYPHARMACYID: 'https://api.covermyday.com/pharmacy/',
    CREATEJOBPOST: 'https://api.covermyday.com/pharmacy/jobpost/create',
    GETJOBPOSTBYID: 'https://api.covermyday.com/pharmacy/jobpost/',
    UPDATEJOBPOST: 'https://api.covermyday.com/pharmacy/jobpost/',
    GETPHARMACYUSERBYAUTHID:
      'https://api.covermyday.com/pharmacy/pharmacyUser/auth/',
    GETPHARMACYDETAILSBYAUTHID: 'https://api.covermyday.com/pharmacy/auth',
    GETPHARMACYJOBLISTING:
      'https://api.covermyday.com/superadmin/pharmacy/',
    LISTPHARMACYCONTRACT:
      'https://api.covermyday.com/pharmacy/listcontract/',
    GETMEMBERSHIP: 'https://api.covermyday.com/superadmin/membership/pharmacy?state=',
    PHARMACYMEMBERSHIPBYID: 'https://api.covermyday.com/pharmacy/pharmacyUser/',
    PAYMENTDETAILS: 'https://api.covermyday.com/payment/s/config',
    // CREATECUSTOMER:
    //   'https://api.covermyday.com/payment/s/cc/createcustomer',
    CREATECUSTOMER: 'https://api.covermyday.com/payment/s/createcustomer',

    // CREATECUSTOMERACH:
    // 'https://api.covermyday.com/payment/s/ach/createcustomer',
    SEARCHPHARMACIST: 'https://api.covermyday.com/pharmacy/search/',
    SAVEPHARMACIST: 'https://api.covermyday.com/pharmacy/savedpharmacist',
    SAVEDPHARMACISTLIST:
      'https://api.covermyday.com/pharmacy/savedpharmacist/',
    INVITEPHARMACIST:
      'https://api.covermyday.com/pharmacy/invitepharmacist',
    INVITEDPHARMACIST:
      'https://api.covermyday.com/pharmacy/invitedpharmacist/',
    MYHIRE: 'https://api.covermyday.com/pharmacy/hiredpharmacist/',
    LISTPROPOSAL: 'https://api.covermyday.com/pharmacy/listproposal?',
    UPDATEPROPOSAL: 'https://api.covermyday.com/pharmacy/proposal/',
    CREATECONTRACT: 'https://api.covermyday.com/pharmacy/createcontract',
    LISTCONTRACTS: 'https://api.covermyday.com/pharmacy/listcontract/',
    VIEWPROPOSAL: 'https://api.covermyday.com/pharmacy/proposal/',
    WRITEREVIEW: 'https://api.covermyday.com/pharmacy/createreview/',
    GETPHARMACYSUBUSER: 'https://api.covermyday.com/pharmacy/userpermission/',
    CREATEPHARMACYSUBUSER: 'https://api.covermyday.com/pharmacy/user/',
    UPDATEPHARMACYSUBUSER: 'https://api.covermyday.com/pharmacy/userpermission/',
    LISTHIRE: 'https://api.covermyday.com/pharmacy/hire/',
    PHARMACYTRASCATION: 'https://api.covermyday.com/pharmacy/transactions/',
    REDEEMEDVOUCHER: 'https://api.covermyday.com/superadmin/voucher/redeem',
    RESETPASSWORD: 'https://api.covermyday.com/auth/validateresetpasswordlink/',
    RESETPASSWORDFORM: 'https://api.covermyday.com/auth/resetpassword/',
    GETSUBUSERBYAUTHONTICATIONID: 'https://api.covermyday.com/pharmacy/user/',
    CHANGEPASSWORD: 'https://api.covermyday.com/auth/changepassword/',
    LISTJOBPOSTBASEDONPHARMACYUSERID: 'https://api.covermyday.com/pharmacy/jobpost/list/',
    UPDATECONTACTBASEUPONCONTRACTREFERENCEID: 'https://api.covermyday.com/pharmacy/contarct/',
    UPDATESAVEDPHARMACIST: "https://api.covermyday.com/pharmacy/savedpharmacist/",
    LISTCURRENTUSERSPAYMENTMETHODS: 'https://api.covermyday.com/payment/s/modes',
    GETCUSTOMER: 'https://api.covermyday.com/payment/s/customer',
    PAY: 'https://api.covermyday.com/payment/s/pay',
    PAYMENTMODES: 'https://api.covermyday.com/payment/s/modes',
    CCPAYMENTATTACHED: 'https://api.covermyday.com/payment/s/paymethod/attach',
    ACHPAYMENTMETHOD: 'https://api.covermyday.com/payment/s/ach/create',
    UPDATEPAYMENT: 'https://api.covermyday.com/payment/s/customer/',
    DEFAULTKEY: 'https://api.covermyday.com/payment/s/default/paymentmethod/',
    CALCULATECONTRACTCHARGES: 'https://api.covermyday.com/pharmacy/contract/charges/',
    GETWALLETPOINT: 'https://api.covermyday.com/pharmacy/pharmacyUser/auth/',
    UPDATECONTRACTUSERREFID: 'https://api.covermyday.com/pharmacy/contarct/',
    CREATEPAYMENTTRANSATION: 'https://api.covermyday.com/payment/s/create/transaction',
    LISTMYHIREBYPHARMACYUSERREFERENCEID: 'https://api.covermyday.com/pharmacy/myhires/',
    GETPHARMACISTBYPHAMACISTREFEMECEID: 'https://api.covermyday.com/pharmacist/profile/details/',

    // superadmin
    SUPERADMINDASHBOARD: 'https://api.covermyday.com/superadmin/dashboard',
    SUPERADMINPHARMACYLIST: 'https://api.covermyday.com/superadmin/pharmacy',
    SUPERADMINPHARMACISTLIST: 'https://api.covermyday.com/superadmin/pharmacist',
    GETPHARMACYDETAILFORSUPERADMIN: 'https://api.covermyday.com/superadmin/pharmacy/',
    LISTVOUCHER: 'https://api.covermyday.com/superadmin/voucher/list',
    CREATEVOUCHER: 'https://api.covermyday.com/superadmin/voucher/create',
    LISTPHARMACYCREDENTIALREQUEST: 'https://api.covermyday.com/superadmin/credential?isPharmacy=1',
    LISTPHARMACISTCREDENTIALREQUEST: 'https://api.covermyday.com/superadmin/credential?isPharmacy=0',
    CREATEMEMBERSHIP: 'https://api.covermyday.com/superadmin/membership/create',
    LISTMEMBERSHIP: 'https://api.covermyday.com/superadmin/membership/pharmacy?state=all',
    UPDATEPHARMACIST: 'https://api.covermyday.com/pharmacist/',
    UPDATECREDENTIAL: 'https://api.covermyday.com/pharmacist/credential/',
    VIEWPHARMACISTLIST: 'https://api.covermyday.com/superadmin/pharmacist/',
    SUPERADMINUSERLIST: 'https://api.covermyday.com/pharmacy/superadmin/users',
    SENDCHATMESSAGE: 'https://api.covermyday.com/message',
    LISTCHATUSERS: 'https://api.covermyday.com/message/message/users',
    USERCHATDETAILS: 'https://api.covermyday.com/message/message/chat-details',
    GETINVOICEDETAILS: 'https://api.covermyday.com/pharmacist/invoice/details',
    CHAT_SOCKET: 'http://api.covermyday.com:9500',
    S3_ARN: 'https://dev-file-pharmacyhire.s3.amazonaws.com/',
    S3_SIGNED_URL: 'https://api.covermyday.com/filehandler/signedurl/',
    GETMEMBERSHIPDETAILSBYID: 'https://api.covermyday.com/pharmacy/membership/',
    GETSUBSCRIPTIONPLANS: 'https://api.covermyday.com/payment/s/products?type=',
    GETSUBSCRIPTIONDETAILS: 'https://api.covermyday.com/payment/s/get-subscription/',
    UPDATESUBSCRIPTIONPLAN: 'https://api.covermyday.com/payment/s/update-subscription/',
    ACTIVITYLOGPOST: 'https://api.covermyday.com/pharmacist/activity',
    ACTIVITYLOGLIST: 'https://api.covermyday.com/pharmacist/activity/list',
    CREATESUBSCRIPTIONPLAN: 'https://api.covermyday.com/payment/s/create-subscription',
    CANCELSUBSCRIPTIONPLAN: 'https://api.covermyday.com/payment/s/cancel-subscription/',
  },
};




