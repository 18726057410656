<aside class="main-sidebar elevation-4" style="background-color: #172b4d">
  <!-- Brand Logo -->
  <a class="brand-link pt-3">
    <img src="../../../../../../assets/images/imgpsh_full.png" alt="Covermyday Logo" class="brand-image  elevation-3"
      style="height: 35px; margin-left:-5px" />
    <span class="brand-text font-weight-light text-white">COVER MY DAY</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img
          src="dist/img/user2-160x160.jpg"
          class="img-circle elevation-2"
          alt="User Image"
        />
      </div>
      <div class="info">
        <a href="#" class="d-block">Alexander Pierce</a>
      </div>
    </div> -->

    <!-- Sidebar Menu -->
    <nav class="mt-2" *ngIf='sectionn.length===0'>
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class
               with font-awesome or any other icon font library -->
        <li class="nav-item has-treeview menu-open">
          <a class="nav-link" [routerLink]="['/pharmacy/dashboard']" [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }" (click)="viewDashboard()">
            <img src="../../../../../../assets/icons/home.png" style="    width: 15px;" />
            <p class="pl-3">Dashboard</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/pharmacy/jobpost']" [routerLinkActive]="'active'"
            (click)="viewjobpost()">
            <img src="../../../../../../assets/icons/message.png" style="    width: 15px;" />
            <p class="pl-3">Jobs</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a [routerLink]="['/pharmacy/chat']" [routerLinkActive]="'active'" (click)="viewChat()" class="nav-link">
            <img src="../../../../../../assets/icons/message-1.png" style="width: 15px;" />
            <p class="pl-3">Chat</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a [routerLink]="['/pharmacy/contracts']" [routerLinkActive]="'active'" (click)="viewContract()"
            class="nav-link">
            <img src="../../../../../../assets/icons/Frame 2.png" style="    width: 15px;" />
            <p class="pl-3">Contracts</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" [routerLink]="['/pharmacy/hire']" [routerLinkActive]="'active'" (click)="viewhire()">
            <img src="../../../../../../assets/icons/Package.png" style="    width: 15px;" />
            <p class="pl-3">Hires</p>
          </a>
        </li>
        <!-- <li class="nav-item has-treeview">
          <a href="#" class="nav-link" [routerLink]="['/pharmacy/wallet']" [routerLinkActive]="'active'"
            (click)="wallet()">
            <img src="../../../../../../assets/icons/Frame 1.png" style="    width: 15px;" />
            <p class="pl-3">Transactions</p>
          </a>
        </li> -->
        <!-- <li class="nav-item has-treeview">
          <a href="#" class="nav-link">
            <i class="nav-icon fa fa-tree"></i>
            <p>Invitations</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a [routerLink]="['/pharmacy/user']" [routerLinkActive]="'active'" (click)="viewUser()" class="nav-link">
            <img src="../../../../../../assets/icons/user.png" style="    width: 15px;" />
            <p class="pl-3">User</p>
          </a>
        </li> -->
        <li class="nav-item has-treeview" style="cursor: pointer;">
          <a class="nav-link" [routerLinkActive]="'active'" (click)="setting()">
            <img src="../../../../../../assets/icons/settings.png" style="    width: 15px;" />
            <p class="pl-3">
              Settings
              <i class="right fa" aria-hidden="true" [ngClass]='show?" fa-angle-down":" fa-angle-left"'></i>
            </p>
          </a>
          <ul class="nav " [ngClass]='show?"success":"black"'>
            <li class="nav-item">
              <a class="nav-link" [routerLink]="['/pharmacy/settings']" [routerLinkActive]="'active'"
                (click)="viewprofile()">
                <i class="fa fa-user-circle-o" aria-hidden="true" style="  color: #dedbdb;"></i>
                <p class="pl-3">Profile Settings</p>
              </a>
            </li>
            <li class="nav-item" style="cursor: pointer;">
              <a class="nav-link" (click)="logout()">
                <i class="fa fa-sign-out" aria-hidden="true" style="  color: #dedbdb;"></i>
                <p class="pl-3">Logout</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <nav class="mt-2" *ngIf='sectionn.length>0'>
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class
                     with font-awesome or any other icon font library -->
        <li class="nav-item has-treeview" *ngFor="let item of sectionn">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['/pharmacy/'+item.key.toLowerCase()]"
            (click)="view(item.key)" *ngIf='item.value'> <i class="nav-icon fa fa-copy"></i>
            <p>{{item.key}}</p>
          </a>
        </li>
        
        <li class="nav-item has-treeview" style="cursor: pointer;">
          <a class="nav-link" [routerLinkActive]="'active'" (click)="setting()">
            <img src="../../../../../../assets/icons/settings.png" style="    width: 15px;" />
            <p class="pl-3">
              Settings
              <i class="right fa" aria-hidden="true" [ngClass]='show?" fa-angle-down":" fa-angle-left"'></i>
            </p>
          </a>
          <ul class="nav " [ngClass]='show?"success":"black"'>            
            <li class="nav-item" style="cursor: pointer;">
              <a class="nav-link" (click)="logout()">
                <i class="fa fa-sign-out" aria-hidden="true" style="  color: #dedbdb;"></i>
                <p class="pl-3">Logout</p>
              </a>
            </li>
          </ul>
        </li>

        <li class="nav-item has-treeview">
          <a class="nav-link" [routerLinkActive]="'active'" (click)="setting()">
            <i class="nav-icon fa fa-circle"></i>
            <p>
              Settings
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" style="display: none">
            <li class="nav-item">
              <a class="nav-link" (click)="logoutt()">
                <i class="fa fa-circle nav-icon"></i>
                <p>Logout</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>