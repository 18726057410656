<div class="content-wrapper" style="min-height: 1203.6px">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 text-dark">Pharmacist
                    </h1>
                </div>
                <div class="col-sm-6">
                </div>
            </div>
        </div>
    </div>

    <section class=" content">
        <div class="col-12" style="width: 100%;">
            <div class="card" *ngIf='pharmacistlist.length!==0'>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6 ">
                            <h4>Personal Details</h4>
                            <div class="row pt-1">
                                <div class="col-2">
                                  <div *ngIf="profileImageUrl; else noImageTemplate">
                                    <img src="{{s3_arn}}{{profileImageUrl}}" width="70px "
                                      height="70px" style="border-radius: 50%;" />
                                  </div>

                                  <ng-template #noImageTemplate>
                                    <img src="../../../assets/images/placeholder-image.png" width="70px " height="70px"
                                      style="border-radius: 50%;" />
                                  </ng-template>

                                </div>
                                <div class="col-9">
                                    <p class="name">{{pharmacist.firstname}} {{pharmacist.lastname}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <h4>Contact Details</h4>
                            <div class="pt-1">
                                <div class="d-flex">
                                    <div>
                                        <p class="detailheding">E-mail</p>
                                    </div>
                                    <div>
                                        <p class="email">{{pharmacist.email}}</p>
                                    </div>

                                </div>
                                <div class="d-flex">
                                    <div>
                                        <p class="detailheding">Phone</p>
                                    </div>
                                    <div>
                                        <p class="phone">{{pharmacist.phone}}</p>
                                    </div>

                                </div>

                                <div class="d-flex">
                                    <div>
                                        <p class="detailheding">City Preference</p>
                                    </div>
                                    <div>
                                        <p class="phone">{{pharmacist.cityPreference}}</p>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div>
                                        <p class="detailheding">Experience (years)</p>
                                    </div>
                                    <div>
                                        <p class="phone">{{pharmacist.experience}}</p>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div>
                                        <p class="detailheding">Software Skills</p>
                                    </div>
                                    <div>
                                        <p class="phone" *ngFor="let item of pharmacist.softwareProficiency">
                                            {{item.skill}}
                                        </p>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <h4 *ngIf='pharmacistlist.length!==0'>All Business</h4>
            <div class="card" *ngFor="let item of pharmacistlist;index as i;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-4">
                            <p class="pharmacyname">{{item.firstname}} {{item.lastname}}</p>                            

                        </div>
                        <div class="col-3">
                            <p>{{item.phone}}</p>
                        </div>
                        <div class="col-2">
                            <p *ngIf='item.licenseType==1'>Vaccination</p>
                            <p *ngIf='item.licenseType==2'>Pharmacist</p>
                        </div>
                        <!-- <div class="col-3">
                            <p>Payment Method</p>

                        </div> -->

                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <h4 *ngIf='pharmacistlist.length!==0'>All Credentials</h4>
            <div class="card" *ngFor="let item of credentials;index as i;">
                <div class="card-body">
                    <div class="row">
                        <div class="col-10">                            
                            <p>Credential: {{item.credential}}</p>                            
                            <p>State: {{item.state}}</p>
                            <p>Expiry Date: {{item.expiryDate | date }}</p>                          
                        </div>
                        <div class="col-2">
                            <p *ngIf='item.licenseType==1'>Vaccination</p>
                            <p *ngIf='item.licenseType==2'>Pharmacist</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card p-3" style="width: 100%;
                                    height: 550px;" *ngIf='pharmacistlist.length === 0'>
            <div class="card-body">
                <div class=" text-center">
                    <!-- <p class="text-center" style="font-size: 25px;">
                        No Records
                      </p> -->
                    <img src="../../../assets/images/nodata.jpg" height="350px" />
                    <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories -
                            www.freepik.com</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
