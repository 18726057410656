<div class="content-wrapper">
    <div class="content-header">
        <div class="">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <h1 class="m-0 text-dark">Membership
                            </h1>
                        </div>
                        <div class="col-6">
                            <button class="btn  float-right" (click)='modalHideShow()'>Add Membership
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section class=" row content">
        <div class="col-4" style="width: 100%;" *ngFor="let item of membershiplist;index as i;">
            <div class="card voucherinfo">
                <div class="col-12">
                    <div class="">
                        <div class="pt-3">
                            <p class="text-center amount">{{item.name}}</p>
                        </div>
                        <div class="container px-4">
                            <!-- <div class="row">
                                <div class="col-7">
                                    <p class='textshow'>County</p>
                                </div>
                                <div class="col-5">
                                    <p class="pl-4">{{item.county}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7">
                                    <p class='textshow'>State</p>
                                </div>
                                <div class="col-5">
                                    <p class="pl-4">{{item.state}}</p>
                                </div>
                            </div> -->
                            <div class="row">
                                <div class="col-7">
                                    <p class='textshow'>Contract Length</p>
                                </div>
                                <div class="col-5">
                                    <p class="pl-4">{{item.contractLength}} months</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7">
                                    <p class='textshow'>Monthly Cost</p>
                                </div>
                                <div class="col-5">
                                    <p class="pl-4">${{item.monthlyCost || '0.00'}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7">
                                    <p class='textshow'>Per Hire Cost</p>
                                </div>
                                <div class="col-5">
                                    <p class="pl-4">${{item.perHireCost || '0.00'}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-7">
                                    <p class='textshow'>Company Fees</p>
                                </div>
                                <div class="col-5">
                                    <p class="pl-4">{{item.companyFees || '0.00'}}%</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="card p-3" style="width: 100%;
                                    height: 550px;" *ngIf='membershiplist.length === 0'>
        <div class="card-body">
            <div class=" text-center">
                <!-- <p class="text-center" style="font-size: 25px;">
                                        No Records
                                      </p> -->
                <img src="../../../assets/images/nodata.jpg" height="350px" />
                <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories - www.freepik.com</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal" [ngClass]="modalShow ? 'success' : 'black'" role="dialog" data-backdrop="false">
    <div class="modal-dialog">
        <div class="modal-content" style="    border-radius: 16px;">
            <!-- Modal Header -->
            <div class="col-12 " style="border-bottom: 1px solid rgb(206, 202, 202);">
                <div class="row pt-2" style="width: 100%;">
                    <div class="col-8">
                        <h4 class="modal-title heading">Add Membership
                        </h4>
                    </div>
                    <div class="col-4">
                        <i class="fa fa-times float-right " (click)="modalHideShoww()" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="card-body">
                    <form [formGroup]="formVar" novalidate class="" (ngSubmit)="onSubmit()">
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-6"> <label for="exampleFormControlTextarea1">Name</label></div>
                                <div class="col-6"> <input type="text" class="form-control"
                                        (keypress)='onlyText($event)' formControlName="name" [ngClass]="{
                                      'is-invalid': submitted && f.name.errors
                                    }" />
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">
                                            Name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="row form-group">
                                <div class="col-6"> <label for="exampleFormControlTextarea1">State</label></div>
                                <div class="col-6"> <input type="text" class="form-control"
                                        (keypress)='onlyText($event)' formControlName="state" [ngClass]="{
                                                              'is-invalid': submitted && f.state.errors
                                                            }" />
                                    <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                        <div *ngIf="f.state.errors.required">
                                            State is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-6"> <label for="exampleFormControlTextarea1">County</label></div>
                                <div class="col-6"> <input type="text" class="form-control"
                                        (keypress)='onlyText($event)' formControlName="county" [ngClass]="{
                                                                                      'is-invalid': submitted && f.county.errors
                                                                                    }" />
                                    <div *ngIf="submitted && f.county.errors" class="invalid-feedback">
                                        <div *ngIf="f.county.errors.required">
                                            County is required
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="row form-group">
                                <div class="col-6"> <label for="exampleFormControlTextarea1">Monthly Rate </label></div>
                                <div class="col-6"> <input type="text" class="form-control"
                                        (keypress)='onlyNumber($event)' formControlName="monthlyCost" [ngClass]="{
                                                                                      'is-invalid': submitted && f.monthlyCost.errors
                                                                                    }" />
                                    <div *ngIf="submitted && f.monthlyCost.errors" class="invalid-feedback">
                                        <div *ngIf="f.monthlyCost.errors.required">
                                            Monthly Rate is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-6"> <label for="perHireCost">Per Hire Cost ($)</label></div>
                                <div class="col-6"> <input type="text" class="form-control"
                                    (keypress)='onlyNumber($event)' formControlName="perHireCost" [ngClass]="{'is-invalid': submitted && f.perHireCost.errors}" />
                                    <div *ngIf="submitted && f.perHireCost.errors" class="invalid-feedback">
                                        <div *ngIf="f.perHireCost.errors.required">
                                            Per Hire Cost is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-6"> <label for="companyFees">Company Fees (%)</label></div>
                                <div class="col-6"> <input type="text" 
                                    class="form-control" (keypress)='onlyNumber($event)' formControlName="companyFees" [ngClass]="{'is-invalid': submitted && f.companyFees.errors}" />
                                    <div *ngIf="submitted && f.companyFees.errors" class="invalid-feedback">
                                        <div *ngIf="f.companyFees.errors.required">
                                            Company fees is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-6"> <label for="exampleFormControlTextarea1">Contract Length
                                    </label></div>
                                <div class="col-6"> <input type="text" class="form-control"
                                        (keypress)='onlyNumber($event)' formControlName="contractLength" [ngClass]="{
                                                                                      'is-invalid': submitted && f.contractLength.errors
                                                                                    }" />
                                    <div *ngIf="submitted && f.contractLength.errors" class="invalid-feedback">
                                        <div *ngIf="f.contractLength.errors.required">
                                            Contract Length is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-body -->
                        <div class=" text-center ">
                            <button class="btnn">Save</button>
                        </div>
                    </form>
                </div>
                <!-- /.card-body -->
            </div>
        </div>
    </div>
</div>