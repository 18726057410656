<div class="content-wrapper">
    <div class="content-header">
        <div class="col-sm-12">
            <div class="row">
                <!-- <div class="col-5" style="background: #0052CC;height: 640px;">
                    <div class="text-center pt-5 "><img class="imgg" src="../../../assets/images/Rectangle 1526.png" />
                    </div>
                    <h5 class="text-center text-white pt-3">Hire Points</h5>
                    <h2 class="text-center text-white pt-2">
                        <span *ngIf="walletpoint > 0; then thenWalletBlock else elseWalletBlock"></span>
                        <ng-template #thenWalletBlock>${{walletpoint/100}}</ng-template>
                        <ng-template #elseWalletBlock>${{0}}</ng-template>
                    </h2>
                    <div class="row pt-4">
                        <div class="col-3"></div>
                        <div class="col-6">
                            <div class="text-center text-white" (click)='BuyPoint()'>
                                <p class="buypoint">Buy Points <span><i class="fa fa-cart-arrow-down"
                                            aria-hidden="true"></i>
                                    </span></p>
                            </div>
                            <div class="pt-3 mt-4 mb-5 text-center" style="background: #FFFFFF;
box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
border-radius: 14px;    cursor: pointer;
" (click)='RedeemVoucher()'>
                                <img class="text-center " src="../../../assets/icons/Rectangle 1518.png" style=" width: 50px;
height: 50px;" />
                                <p class="text-center pb-3 pt-2 " class="redmeevoucher">Redeem Voucher</p>
                            </div>
                        </div>

                    </div>


                </div> -->

                <div class="col-12">
                    <h1>All Transactions</h1>
                    <div class="card mt-3" *ngIf='walletlist.length !== 0'>
                        <div class="card-body">
                            <div class="d-flex row " *ngFor="let item of walletlist">
                                <div class="col-8">
                                    <div class="d-flex">
                                        <div class="pr-5" style="font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            ">+</div>
                                        <div *ngIf='item.transactionType==="buyPoints"' style="font-family: 'FontAwesome';
                         font-style: normal;
                         font-weight: normal;
                         font-size: 20px;margin-top: -10px;
                         ">Buy Points<br />
                                            <p style="font-size: 14px;">{{item.createdAt | date:" dd MMM "}}</p>
                                        </div>
                                        <div *ngIf='item.transactionType==="hirePharmacist"' style="font-family: 'FontAwesome';
                         font-style: normal;
                         font-weight: normal;
                         font-size: 20px;margin-top: -10px;
                         ">Hire Pharmacist<br />
                                            <p style="font-size: 14px;">{{item.createdAt | date:" dd MMM "}}</p>
                                        </div>
                                        <div *ngIf='item.transactionType==="Voucher Redeemed"' style="font-family: 'FontAwesome';
                         font-style: normal;
                         font-weight: normal;
                         font-size: 20px;margin-top: -10px;
                         ">Redeem Voucher<br />
                                            <p style="font-size: 14px;">{{item.createdAt | date:" dd MMM "}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 ">
                                    <div *ngIf='item.transactionType==="buyPoints"' class="float-right" style="font-family: Rubik;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            color: #00FF66;
            "><span class="pr-2">+</span>${{item.amount/100}}</div>
                                    <div class="float-right" *ngIf='item.transactionType==="hirePharmacist"' style="font-family: Rubik;
              font-style: normal;
              font-weight: normal;
              font-size: 20px;
              color: #FF0F00;"><span class="pr-2">-</span>${{item.amount/100}}</div>
                                    <div class="float-right" *ngIf='item.transactionType==="Voucher Redeemed"' style="font-family: Rubik;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 20px;
                    color: #00FF66;
                    "><span class="pr-2">+</span>${{item.amount}}</div>

                                </div>


                            </div>
                            <div class="mt-0" *ngIf='walletlist.length !== 0'>
                                <ngb-pagination [collectionSize]="collectionsize" [(page)]="page"
                                    (pageChange)="reloadDataa()" [pageSize]="9" [maxSize]="4" [boundaryLinks]="true">
                                </ngb-pagination>
                            </div>


                        </div>
                    </div>
                    <div class="card mt-3" *ngIf='walletlist.length === 0' style="height: 580px;">
                        <div class="card-body">
                            <div class=" text-center">
                                <!-- <p class="text-center" style="font-size: 25px;">
              No Records
            </p> -->
                                <img src="../../../assets/images/nodata.jpg" height="350px" />
                                <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories -
                                        www.freepik.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>



<div class="modal" id="myModal" [ngClass]="modalShow ? 'success' : 'black'" role="dialog" data-backdrop="false">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <div class="text-center ">
                    <h4 class=" text-center">Redeem a Voucher <span>
                            <i class="fa fa-times float-right " (click)="modalHideShoww()" aria-hidden="true"></i>

                        </span></h4>
                </div>
                <!-- <form > -->
                <div class="card-body">
                    <div class="text-center">
                        <img src="../../../../assets/images/female-pharmacist-home.jpg" width="50px " height="50px"
                            style="border-radius: 50%;" />
                        <h5>Name</h5>
                    </div>
                    <form [formGroup]="formVarr" (ngSubmit)="onSubmitt()">
                        <div class="card-body">
                            <div class="col-sm-12">

                                <div class="form-group">
                                    <input type="" class="form-control" formControlName="code" [ngClass]="{
                        'is-invalid': submittedd && ff.code.errors
                      }" />
                                    <div *ngIf="submittedd && ff.code.errors" class="invalid-feedback">
                                        <div *ngIf="ff.code.errors.required">
                                            Code is required
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- /.card-body -->
                        <div class="text-center">
                            <button type="submit" class=" btnn">Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <!-- 
                                <button type="button" class="btn btn-danger" data-dismiss="modal"
                                    ">
                                    Close</button><br /> -->
                        </div>
                    </form>
                </div>
                <!-- /.card-body -->
                <!-- </form> -->
            </div>
        </div>
    </div>
</div>

<!-- Buy Poit -->

<div class="modal" id="myModal" [ngClass]="modalShoww ? 'success' : 'black'" role="dialog" data-backdrop="false">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body">
                <div>
                    <span>
                        <i class="fa fa-times float-right " (click)="modalHideeShow()" aria-hidden="true"></i>

                    </span>
                </div>
                <!-- <form> -->
                <div class="card-body">

                    <div class="text-center">
                        <img src="../../../../assets/images/Rectangle 1537.png" width="80px " height="70px" />
                        <h5>Purchase Points</h5>
                    </div>
                    <form [formGroup]="formVar" (ngSubmit)="onSubmit()">
                        <div class="card-body">
                            <div class="col-sm-12">

                                <div class="form-group">
                                    <input type="number" class="form-control" formControlName="amount" [ngClass]="{
                        'is-invalid': submitted && f.amount.errors
                      }" />
                                    <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                                        <div *ngIf="f.amount.errors.required">
                                            amount is required
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- /.card-body -->
                        <div class="text-center">
                            <button type="submit" class=" btnn">Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            <!-- 
                                <button type="button" class="btn btn-danger" data-dismiss="modal"
                                    ">
                                    Close</button><br /> -->
                        </div>
                    </form>
                </div>
                <!-- /.card-body -->
                <!-- </form> -->
            </div>
        </div>
    </div>
</div>