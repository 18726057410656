import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../../app.settings';
import { Observable, throwError, from, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Headers, RequestOptions, Request, RequestMethod } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  constructor(private http: HttpClient) { }
    
   public getvoucher(page): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.LISTVOUCHER+`?limit=9&offset=${(page - 1) * 9}`)
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(this.errorHandler)
      );
   }
    Createvoucher(data) {
    console.log('CreatePharmacyUser before api call auth.service');
    return this.http
      .post<any>(AppSettings.apiurls.CREATEVOUCHER, { ...data })
      .pipe(
        map((response) => {
          return JSON.stringify(response);
        }),
        catchError(this.errorHandler)
      );
  }
    errorHandler(error: any) {
    console.log('Error--->', error);
    return throwError(error);
  }
}
