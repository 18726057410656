import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-superadmin-header',
  templateUrl: './superadmin-header.component.html',
  styleUrls: ['./superadmin-header.component.css']
})
export class SuperadminHeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
