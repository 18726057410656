import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../dashboard/dashboard.service';
import { first } from 'rxjs/operators';
import { Router, CanActivate, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { PaymentType } from '../../../shared/enum/constants';
import * as moment from 'moment';

@Component({
  selector: 'app-activity',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.css'],
})

export class ActivityLogComponent implements OnInit {
  Object = Object;
  showw = true;
  open = false;
  close = true
  page = 1;  
  public errorMessage: any;
  public logs = [];

  constructor(    
    //private route: ActivatedRoute,
    //private router: Router,    
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService

  ){
    // let pharmacylistLocal = localStorage.getItem('pharmacylist');  
  }

  ngOnInit(): void {
    this.getActivityLogs(this.page);    
  }

  getActivityLogs(page): void {
    
    this.spinner.show();
    this.dashboardService
      .getActivityLogs(page)
      .pipe(first())
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data) {                    
            this.logs = data?.data;
            console.log('this.logs', this.logs);
            // this.errorMessage = '';

          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }
}
