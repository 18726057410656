<div class="content-wrapper">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mx-2">
                <div class="col-6">
                    <h3>Chat</h3>
                </div>
            </div>
        </div>
    </div>
    <section class="content">
        <div class="col-12">
            <div class="row rounded-lg overflow-hidden shadow" id="conttainer">
                <!-- Users box-->
                <div class="col-4 px-0">
                    <div class="bg-white">
                        <div class="bg-gray px-4 py-2 bg-light">
                            <p class="h5 mb-0 py-1">Users</p>
                        </div>
                        <div class="messages-box">
                            <div class="list-group rounded-0">
                                <a href="javascript:void(0)"
                                    class="list-group-item list-group-item-action list-group-item-light rounded-0 {{sendTo == user.pharmacistReferenceId ? 'activeUser' : ''}}"
                                    *ngFor="let user of chatUsers" (click)="selectSendTo(user)">
                                    <div class="media">
                                        <div *ngIf="user.pharmacistProfileImageUrl; else noImageTemplate">
                                            <img src="{{s3_arn}}{{user.pharmacistProfileImageUrl}}"
                                                alt="user" width="50" class="rounded-circle">
                                        </div>

                                        <ng-template #noImageTemplate>
                                            <img src="../../../assets/images/placeholder-image.png" alt="user"
                                                width="50" class="rounded-circle">
                                        </ng-template>
                                        <div class="media-body ml-4">
                                            <div class="d-flex align-items-center justify-content-between mb-1">
                                                <h6 class="mb-0">{{user.pharmacistName}}</h6><small
                                                    class="small font-weight-bold">{{user.createdAt | date}}</small>
                                            </div>
                                            <p class="font-italic text-muted mb-0 text-small">{{user.message}}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Chat Box-->
                <div class="col-8 px-0">
                    <div class="px-4 py-5 chat-box bg-white" id="messages" #scrollMe
                        style="overflow: scroll; height: 500px;">
                        <div *ngFor="let message of messageList">
                            <div *ngIf="(message.sender == 2)">
                                <div class="row media w-100 mb-3 pull-right">
                                    <div *ngIf="message.pharmacistProfileImageUrl; else noImageTemplate">
                                        <img src="{{s3_arn}}{{message.pharmacistProfileImageUrl}}"
                                            alt="user" width="50" class="rounded-circle">
                                    </div>

                                    <ng-template #noImageTemplate>
                                        <img src="../../../assets/images/placeholder-image.png" alt="user" width="50"
                                            class="rounded-circle">
                                    </ng-template>
                                    <div class="media-body ml-3">
                                        <div class="bg-light rounded py-2 px-3 mb-2">
                                            <p class="text-small mb-0 text-muted">{{message.message}}</p>
                                        </div>
                                        <p class="small text-muted">{{message.pharmacistName}}, {{message.createdAt |
                                            date:'short'}}</p>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="message.sender == 1">
                                <div class="media w-100 mb-3">
                                    <div class="media ml-auto mb-3">
                                        <div class="media-body">
                                            <div class="bg-primary rounded py-2 px-3 mb-2">
                                                <p class="text-small mb-0 text-white">{{message.message}}</p>
                                            </div>
                                            <p class="small text-muted">{{message.createdAt | date:'short'}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Typing area -->
                    <form #f="ngForm" (ngSubmit)="SendMessage()" class="bg-light" novalidate>
                        <div class="input-group">
                            <input type="text" placeholder="{{sendMessagePlaceholder}}" aria-describedby="button-addon2"
                                class="form-control rounded-0 border-0 py-4 bg-light" #messageId
                                (keyup)="UserType(messageId.value)" value="{{message}}">
                            <div class="input-group-append">
                                <button id="button-addon2" type="submit" class="btn btn-link" [disabled]="!message">
                                    <i class="fa fa-paper-plane"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
</div>