import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../app.settings';
import { Observable, throwError, from, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Headers, RequestOptions, Request, RequestMethod } from '@angular/http';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}

  public getPharmacyJoblisting(pharmacyRefrenceId, page): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.GETPHARMACYJOBLISTING +
          pharmacyRefrenceId +
          `/joblisting?limit=3&offset=${(page - 1) * 3}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.errorHandler)
      );
  }

  public getPharmacyContracts(authenticationId,page): Observable<any> {
    return this.http
      .get(AppSettings.apiurls.LISTPHARMACYCONTRACT + authenticationId
        +        `?limit=3&offset=${(page - 1) * 3}`
        )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.errorHandler)
      );
  }
   Cancelhire(data, contractid) {
    return this.http
      .put<any>(AppSettings.apiurls.UPDATECONTACTBASEUPONCONTRACTREFERENCEID + contractid, data)
      .pipe(
        map((cancelhire) => {
         
          return JSON.stringify(cancelhire);
        }),
        catchError(this.errorHandler)
      );
  }

  errorHandler(error: any) {
    console.log('Error--->', error);
    return throwError(error);
  }

  ActivityLogPost(data) {
    return this.http
      .post<any>(AppSettings.apiurls.ACTIVITYLOGPOST, { ...data })
      .pipe(
        map((result) => {
          return JSON.stringify(result);
        }),
        catchError(this.errorHandler)
      );
  }

  public getActivityLogs(page): Observable<any> {
    return this.http
      .get(AppSettings.apiurls.ACTIVITYLOGLIST + `?page=${page}`)
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.errorHandler)
      );
  }

}
