import { Component, OnInit } from '@angular/core';
import { PharmacistService } from './pharmacist.service';
import { first } from 'rxjs/operators';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ToastrService } from '../../../toastr.service'
import { NgxSpinnerService } from "ngx-spinner";
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-pharmacist',
  templateUrl: './pharmacist.component.html',
  styleUrls: ['./pharmacist.component.css']
})
export class PharmacistComponent implements OnInit {
  formVar: FormGroup;
  public errorMessage: any
  public collectionsize: any;
  public pharmacistlist = [];
  page = 1;
  public clickedid = ""
  open = false;
  close = true
  public filter = 'all';
  public valuee: '';
  public filterkey = [];
  public s3_arn: any;
  constructor(
    private Pharmacist: PharmacistService,
    private router: Router,
    private fb: FormBuilder,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService

  ) {
    this.s3_arn = AppSettings.apiurls.S3_ARN;
  }
  ngOnInit(): void {
    this.filterkey = [{ key: 'All', value: 'all' }, { key: 'Name', value: 'name' }, { key: 'Pharmacist Status', value: 'pharmacistStatus' }, { key: 'Count Job post', value: 'countJobpost' }]

    this.formVar = this.fb.group({
      search: [''],
    });
    let role = JSON.parse(localStorage.getItem('currentUser')).data?.role
    if (role === 1) {
      this.Totalpharmacistlist(this.filter, this.valuee, this.page)
    }
    else if (role === 5) {
      this.Totalpharmacistlist(this.filter, this.valuee, this.page)
    }
    else {
      this.router.navigate(['/login']);

    }
  }
  openclose(id) {
    this.open = !this.open
    this.clickedid = id
    console.log(this.open)
  }
  closeopen(id) {
    this.close = true
    this.clickedid = ""
    console.log(this.open)

  }
  reloadData() {
    this.Totalpharmacistlist(this.filter, this.valuee, this.page)
  }
  viewdetails(refid) {
    this.router.navigate(['/superadmin/pharmacist/viewdetail'], {
      queryParams: { id: btoa(refid) },
    });

  }

  editdetails(id) {

  }
  toNumber(n) {
    return Number(n)
  }

  deletedetalis(pharmacistReferenceId) {
    var request = {
      isDeleted: 1

    }
    Swal.fire({
      title: 'Are you sure?',
      text: "You want to delete this jobpost!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Deleted!',
          'Your jobpost has been deleted.',
          'success'
        )
        this.Pharmacist
          .updatePharmacist(request, pharmacistReferenceId)
          .subscribe(
            (data) => {
              if (data) {
                this.errorMessage = '';
                console.log('Job Post Updated', JSON.parse(data));
                // this.tosterservice.Success('Your jobpost has been deleted Successfully')
                this.Totalpharmacistlist(this.filter, this.valuee, this.page)

              } else {
                this.errorMessage = 'Unable to Save Jobpost';
                console.log('Unable to Save Jobpost');
              }
            },
            (error) => {
              console.log('Error Message: ', error.error);
              this.errorMessage = error.error;
            }
          );
      }
    })

  }


  searchkey(event) {
    this.valuee = event
    this.Totalpharmacistlist(this.filter, this.valuee, this.page)
  }
  onOptionsSelected(filtername) {
    this.filter = filtername
    if (this.valuee === this.formVar.value.search) {
      this.Totalpharmacistlist(this.filter, this.valuee, this.page)
    }
  }


  Totalpharmacistlist(filter, value, page): void {
    this.spinner.show();

    this.Pharmacist
      .getpharmacistlist(filter, value, page)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log('Superadmin pharmacy ===>', data);
          if (data) {
            this.errorMessage = '';
            var dataaa = data;
            this.collectionsize = dataaa?.data?.pagination?.totalCount;

            this.pharmacistlist = dataaa?.data?.listResult;
            this.spinner.hide();

          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }
}
