import { Component, OnInit } from '@angular/core';
import { VoucherService } from '../voucher/voucher.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from '../../../toastr.service'
import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common'

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})

export class VoucherComponent implements OnInit {
  formVar: FormGroup;
  page = 1;
  public errorMessage: any
  public voucherlist = [];
  public modalShow = false;
  submitted = false;
  public todayDate: any;
  public collectionsize: any
  constructor(
    private voucher: VoucherService,
    private router: Router,
    private tosterservice: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService

  ) { }
  ngOnInit(): void {
    this.todayDate = moment(new Date()).format('YYYY-MM-DD');
    this.formVar = this.fb.group({
      voucherName: ['', [Validators.required]],
      numberOfVouchers: ['', [Validators.required]],
      expiryDate: ['', [Validators.required]],
      amount: ['', [Validators.required, Validators.min(1)]],
    });
    let role = JSON.parse(localStorage.getItem('currentUser')).data?.role
    if (role === 1) {
      this.getvoucherdetail(this.page)
    }
    else if (role === 5) {
      this.getvoucherdetail(this.page)
    }
    else {
      this.router.navigate(['/login']);

    }
  }
  get f() {
    return this.formVar.controls;
  }
  modalHideShow() {
    this.formVar.reset();
    this.submitted = false;
    this.modalShow = !this.modalShow;
  }
  modalHideShoww() {
    this.modalShow = !this.modalShow;

  }
  reloadData() {
    this.getvoucherdetail(this.page)

  }

  getDate(dateString) {
    var todayTime = new Date(dateString);
    var month = todayTime.getMonth() + 1;
    var day = todayTime.getDate();
    var year = todayTime.getFullYear();
    return day + "-" + month + "-" + year;
  }

  getvoucherdetail(page): void {
    this.spinner.show();

    this.voucher
      .getvoucher(page)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data) {
            this.errorMessage = '';
            console.log(data);
            this.voucherlist = data.data.listResult
            this.collectionsize = data.data.pagination.totalCount
            this.spinner.hide();

          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }

  onlyNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;

    }

    return true;
  }
  onlyText(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) && (charCode >= 96 || charCode <= 105)) {
      //console.log(charCode)
      return false;

    }
    return true;

  }


  onSubmit() {
    this.submitted = true;
    if (this.formVar.invalid) {
      return;
    }
    this.voucher.Createvoucher(this.formVar.value).subscribe(
      (dataa) => {
        if (dataa) {
          this.errorMessage = '';
          this.tosterservice.Success('Your Voucher Created Successfully')
          this.getvoucherdetail(this.page)
          this.modalShow = false;


        } else {
          this.errorMessage = 'Unable to Create Voucher';
          console.log('Unable to Create Voucher');
        }
      },
      (error) => {
        console.log('Error Message: ', error.error);
        this.errorMessage = error.error;
      }
    );

  }

}
