<aside class="main-sidebar elevation-4" style="background-color: #172b4d">
  <!-- Brand Logo -->
  <a class="brand-link pt-3">
    <img src="../../../../../../assets/images/imgpsh_full.png" alt="Covermyday Logo" class="brand-image  elevation-3"
      style="height: 35px; margin-left:-5px" />
    <span class="brand-text font-weight-light text-white">COVER MY DAY</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <nav class="mt-2" *ngIf='sectionn.length===0'>
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-item has-treeview menu-open">
          <a class="nav-link" [routerLink]="['/superadmin/dashboard']" [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }" (click)="viewDashboard()">
            <img src="../../../../../../assets/icons/home.png" style="    width: 15px;" />
            <p class="pl-3">Dashboard</p>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['/superadmin/pharmacy']" [routerLinkActive]="'active'"
            (click)="viewPharmacy()">
            <img src="../../../../../../assets/icons/home.png" style="    width: 15px;" />
            <p class="pl-3">Pharmacy</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" [routerLink]="['/superadmin/pharmacist']" [routerLinkActive]="'active'"
            (click)="viewPharmacist()">
            <i class="fa fa-user-circle-o" aria-hidden="true" style="  color: #dedbdb;"></i>
            <p class="pl-3">Pharmacist</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" [routerLink]="['/superadmin/voucher']" [routerLinkActive]="'active'"
            (click)="viewvoucher()">
            <img src="../../../../../../assets/icons/message-square (1).png" style="    width: 15px;" />
            <p class="pl-3">Voucher</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" [routerLink]="['/superadmin/credentialrequest']" [routerLinkActive]="'active'"
            (click)="viewcredential()">
            <img src="../../../../../../assets/icons/Frame 1.png" style="    width: 15px;" />
            <p class="pl-3">Credential request</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['/superadmin/user']" (click)="viewuser()">
            <img src="../../../../../../assets/icons/user.png" style="    width: 15px;" />
            <p class="pl-3">User</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" [routerLink]="['/superadmin/membership']" [routerLinkActive]="'active'"
            (click)="viewmembership()">
            <img src="../../../../../../assets/icons/Vector (2).png" style="    width: 15px;color: white;" />
            <p class="pl-3">Membership</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" [routerLink]="['/superadmin/activity-logs']" [routerLinkActive]="'active'"
            (click)="viewActivityLogs()">
            <img src="../../../../../../assets/icons/Frame 1.png" style="width: 15px;color: white;" />
            <p class="pl-3">Activity Logs</p>
          </a>
        </li>
        <li class="nav-item has-treeview">
          <a class="nav-link" (click)="logoutt()" style="color: white;cursor: pointer;">
            <i class="fa fa-sign-out" aria-hidden="true"></i>
            <p class="pl-3">Logout</p>
          </a>
        </li>
      </ul>
    </nav>

    <nav class="mt-2" *ngIf='sectionn.length>0'>
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <!-- Add icons to the links using the .nav-icon class
                         with font-awesome or any other icon font library -->

        <li class="nav-item has-treeview" *ngFor="let item of sectionn">
          <a class="nav-link" [routerLinkActive]="'active'" [routerLink]="['/superadmin/'+item.key.toLowerCase()]"
            (click)="view(item.key)"> <i class="nav-icon fa fa-copy"></i>
            <p>{{item.key}}</p>
          </a>
        </li>
        <li class="nav-item has-treeview" style="cursor: pointer;">
          <a class="nav-link" [routerLinkActive]="'active'" (click)="setting()">
            <i class="nav-icon fa fa-circle"></i>
            <p>
              Settings
              <i class="right fa fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview" style="display: none">

            <li class="nav-item">
              <a class="nav-link" (click)="logoutt()">
                <i class="fa fa-circle nav-icon"></i>
                <p>Logout</p>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </nav>

    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>