<!-- <p>unauth-layout works!</p>
<h1>Header</h1>-->
<style>
    .loader ::ng-deep .overlay {
        position: fixed !important;
        background-color: white;
    }
</style>
<div style="position: fixed;
    z-index: 99;">

    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.4)" class="loader" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="false">
        <p style="color: white;top: 0;
   "> Loading... </p>
    </ngx-spinner>
</div>
<router-outlet></router-outlet>
<!--<h1>Footer</h1>-->
