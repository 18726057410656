import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AppSettings } from 'src/app/app.settings';
import { ContractsService } from '../contracts/contracts.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { DashboardService } from '../../dashboard/dashboard.service';
import * as moment from 'moment';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.css'],
})
export class ContractsComponent implements OnInit {
  formVar: FormGroup;
  page = 1;
  countt = 0;
  public submitted = false
  public modalShow = false;
  public modalShoow = false;
  public errorMessage: any;
  public authenticationId: any;
  public contractlist = [];
  public contractId: any;
  public pharmacistId: any
  public collectionsize: any;
  public pharmacistName: any
  public rating = null;
  public descriptinn: any
  public todaysDate: any;
  public s3_arn: any;

  constructor(
    private contracts: ContractsService,
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private dashboard: DashboardService,

    config: NgbPaginationConfig
  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
    this.s3_arn = AppSettings.apiurls.S3_ARN;
  }
  ngOnInit(): void {

    this.authenticationId = localStorage.getItem('signupUserReferenceId');
    this.getcontractlist(this.authenticationId, this.page)
    this.formVar = this.fb.group({
      description: ['', Validators.required],
    });

    this.todaysDate = moment().format('YYYY-MM-DD');

  }
  get f() {
    return this.formVar.controls;
  }

  modalHideShow(contractid, pharmacistid, pharmacistName) {
    // alert(contractid);
    // alert(pharmacistid)
    this.formVar.reset();
    this.submitted = false;
    this.contractId = contractid
    this.pharmacistId = pharmacistid
    this.pharmacistName = pharmacistName
    this.modalShow = !this.modalShow;
  }
  modalHideShoww() {
    this.modalShow = false;
    this.countt = null
    
    this.descriptinn = "";
    this.rating = 0;    
  }
  modalHideShhhow(contractid, pharmacistid, pharmacistName, description, rating) {

    this.modalShoow = !this.modalShow;
    this.descriptinn = description,
    this.rating = rating
    this.pharmacistName = pharmacistName

  }
  modalHideShhoww() {
    this.modalShoow = false;
    this.descriptinn = "";
    this.rating = 0;
  }
  counter(i) {
    return new Array(i);
  }
  toNumber(n) {
    return Number(n)
  }
  count(value) {
    // if(this.countt<5){
    //   this.countt=this.countt+1;
    //   console.log(this.countt)
    //   return
    // }
    this.countt = value
  }

  reloadDataa() {
    this.getcontractlist(this.authenticationId, this.page)
  }
  getcontractlist(authonticatinid, page): void {
    this.spinner.show();

    this.contracts
      .getlistcontracts(authonticatinid, page)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data) {
            this.errorMessage = '';
            this.contractlist = data.data.listResult;
            this.collectionsize = data.data.pagination.totalCount

            this.spinner.hide();

          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    if (this.formVar.invalid) {
      return;
    }
    var request = {
      contractId: this.contractId,
      pharmacistId: this.pharmacistId,
      description: this.formVar.value.description,
      ratings: this.countt
    };

    this.contracts.writereview(request).subscribe(
      (data) => {
        if (data) {
          this.errorMessage = '';
          this.modalShow = false;
          this.getcontractlist(this.authenticationId, this.page)

        } else {
          this.errorMessage = 'Unable to Save review';
          console.log('Unable to Save review');
        }
      },
      (error) => {
        console.log('Error Message: ', error.error);
        this.errorMessage = error.error;
      }
    );
  }

  closethecard(contractReferenceId) {
    var request = {
      //  title: this.contrecttitle,
      status: "cancelledbypharmacy"
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dashboard
          .Cancelhire(request, contractReferenceId)
          .subscribe(
            (data) => {
              if (data) {
                this.errorMessage = '';
                var dataa = JSON.parse(data)
                this.getcontractlist(this.authenticationId, this.page)

              } else {
                this.errorMessage = 'Unable to Save Contract';
              }
            },
            (error) => {
              console.log('Error Message: ', error.error);
              this.errorMessage = error.error;
            }

          );
        Swal.fire(
          'Deleted!',
          'Your contract has been deleted.',
          'success'
        )
      }
    })
  }

  gopharmacistprofile(refid) {
    this.router.navigate(['pharmacy/jobpost/viewpharmacist'], {
      queryParams: { pharmacistRefid: btoa(refid), contract: true },
    });
  }
}
