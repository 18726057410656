import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../dashboard/dashboard.service';
import {HireService} from '../hire/hire.service'
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { JobpostService } from '../jobpost/jobpost.service';
import Swal from 'sweetalert2';
import {ToastrService} from '../../toastr.service'
import { from } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { PaymentType } from '../../shared/enum/constants';
import { AppSettings } from 'src/app/app.settings';
import * as moment from 'moment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
  providers: [NgbPaginationConfig],
})
export class DashboardComponent implements OnInit {
  Object = Object;
  page = 1;
  page1 = 1
  modalShow=false
  public collectionsize: number;
  public collectionsizecontract :number
  public errorMessage: any;
  public jobListing = {};
  public contracts=[];
  public pharmacyUser: any;
  public authenticationId: any;
  open = false;
  close = false;
  public name: any;
  public finalcost: any;
  public datetime: any;
  public contractrefid: any;
  public clickedid = ""
  public contrecttitle: any;
  public expextedhour: any;
  public paymentTypee: any;
  public s3_arn: any;
  public todaysdate: any;
  
  constructor(
    private dashboard: DashboardService,
    private Hire :HireService,
    private router: Router,
    config: NgbPaginationConfig,
    private jobpostService: JobpostService,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
    this.s3_arn = AppSettings.apiurls.S3_ARN;
    this.todaysdate = moment(new Date()).format('YYYY-MM-DD');
  }

  ngOnInit(): void {
    this.paymentTypee = PaymentType;
    this.pharmacyUser = JSON.parse(localStorage.getItem('pharmacyUserId'));
    this.authenticationId = localStorage.getItem('signupUserReferenceId');
    if (this.pharmacyUser) {
    this.getjoblisting(this.pharmacyUser.data.referenceId, this.page);

    }
    this.getContract(this.authenticationId,this.page);
  }

 openclose(id) {
    this.open = !this.open
    this.clickedid = id
  }
  closeopen(id) {
      this.close =true
    this.clickedid = ""

  }

  counter(i){
    return new Array(i);
  }
  toNumber(n){
    return Math.ceil(Number(n))
      }

    viewjobdetals(id, jobId) {
    this.router.navigate(['pharmacy/jobpost/view'], {
      queryParams: { id: btoa(id), jobId: btoa(jobId) },
    });
  }

  editjobdetails(id) {
    this.router.navigate(['pharmacy/jobpost/edit']);
    localStorage.setItem(
      'currentJobpost', id);
     localStorage.setItem('ppharmacyid',JSON.parse(
      localStorage.getItem('pharmacylist'))?.data[0].id
    )
        localStorage.setItem('paymentvalue',this.paymentTypee[0].Value)
  }
 deletejobdetalis(jobReferenceId){
    var request = {
      isDeleted: 1

    }
    Swal.fire({
  title: 'Are you sure?',
  text: "You want to delete this jobpost!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, delete it!'
}).then((result) => {
  if (result.isConfirmed) {
    Swal.fire(
      'Deleted!',
      'Your jobpost has been deleted.',
      'success'
    )
      this.jobpostService
    .updateJobPost(request, jobReferenceId)
    .subscribe(
      (data) => {
        if (data) {
          this.errorMessage = '';
            // this.tosterservice.Success('Your jobpost has been deleted Successfully')
          this.getjoblisting(this.pharmacyUser.data.referenceId, this.page);

        } else {
          this.errorMessage = 'Unable to Save Jobpost';
          console.log('Unable to Save Jobpost');
        }
      },
      (error) => {
        console.log('Error Message: ', error.error);
        this.errorMessage = error.error;
      }
    );
  } 
})
  
  }
  
  showalldata() {
      this.router.navigate(['pharmacy/jobpost']);

  }
  showallallhire() {
          this.router.navigate(['pharmacy/hire']);

  }
  reloadData() {
    this.getjoblisting(this.pharmacyUser.data.referenceId, this.page);
  }
  reloadDataa(){
    this.getContract(this.authenticationId,this.page1);

  }
  getjoblisting(pharmacyRefrenceId, page): void {
            this.spinner.show();

    this.dashboard
      .getPharmacyJoblisting(pharmacyRefrenceId, page)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data) {
            this.errorMessage = '';

            var dataaa = data;
             this.collectionsize = dataaa?.data?.pagination?.totalCount;

            this.jobListing = dataaa?.data?.listResult;
               this.spinner.hide();

          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }

  getContract(authenticationId,page): void {
    this.Hire
      .getlisthire(authenticationId,page)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data) {
            this.errorMessage = '';
            this.contracts = data.data.listResult;
            this.collectionsizecontract = data.data.pagination.totalCount;

          }
        },
        (error) => {
          console.log('Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }
  modalHideShoww(name,datetime,cost,contractid,title,expextedhour) {
    this.modalShow = !this.modalShow;
    this.name = name;
    this.finalcost = cost;
    this.datetime = datetime;
    this.contractrefid = contractid;
    this.contrecttitle = title,
      this.expextedhour =expextedhour
  }
  modalHide() {
        this.modalShow=false

  }
  submit() {
    var request = {
      //  title: this.contrecttitle,
    status: "cancelledbypharmacy"
    }
    Swal.fire({
  title: 'Are you sure?',
  text: "You won't be able to revert this!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#3085d6',
  cancelButtonColor: '#d33',
  confirmButtonText: 'Yes, cancel it!'
}).then((result) => {
  if (result.isConfirmed) {
     this.dashboard
      .Cancelhire(request, this.contractrefid)
      .subscribe(
        (data) => {
          if (data) {
            this.errorMessage = '';
                    this.modalShow=false
    this.getContract(this.authenticationId,this.page1);

  // this.tosterservice.Success('You Canceld The Hire Successfully')
            
          } else {
            this.errorMessage = 'Unable to Save Jobpost';
            console.log('Unable to Save Jobpost');
          }
        },
        (error) => {
          console.log('Error Message: ', error.error);
          this.errorMessage = error.error;
        }
      );
    Swal.fire(
      'Canceled!',
      'Your Hire has been Canceled.',
      'success'
    )
  }
})
    
  }
}
