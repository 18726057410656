import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) { }
  activeTab = 'dashboard';
  public sidename: any;
  public show = false;
  public sectionn = []
  ngOnInit(): void {    
    if (JSON.parse(localStorage.getItem('subusersection'))) {
      this.sectionn = JSON.parse(localStorage.getItem('subusersection'))[0].section
      console.log(JSON.parse(localStorage.getItem('subusersection'))[0].section)

    }
  }
  logout() {
    localStorage.removeItem('pharmacyUser');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('signupUserReferenceId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('pharmacyUserId');
    localStorage.removeItem('pharmacylist');
    localStorage.removeItem('joblistvalue');
    localStorage.removeItem('subuseritem')
    localStorage.removeItem('sidebarstringifyobj')
    localStorage.removeItem('sideBarObject')
    localStorage.removeItem('topprogressbarline')
    localStorage.removeItem('subusersection')
    localStorage.removeItem('membershipname')
    localStorage.removeItem('ppharmacyid')
    localStorage.removeItem('paymentvalue')
    localStorage.removeItem('stripecustomerId')
    localStorage.removeItem('Paymentmethodvalue')


    this.router.navigate(['/login']);
  }
  logoutt() {
    localStorage.removeItem('pharmacyUser');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('signupUserReferenceId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('pharmacyUserId');
    localStorage.removeItem('pharmacylist');
    localStorage.removeItem('joblistvalue');
    localStorage.removeItem('subusersection')
    localStorage.removeItem('subuseritem')
    localStorage.removeItem('sidebarstringifyobj')
    localStorage.removeItem('sideBarObject')
    localStorage.removeItem('topprogressbarline')
    localStorage.removeItem('stripecustomerId')
    localStorage.removeItem('Paymentmethodvalue')
    this.router.navigate(['/login']);
  }
  viewDashboard() {
    this.activeTab = 'dashboard';
    this.router.navigate(['pharmacy/dashboard']);
  }
  viewjobpost() {
    this.activeTab = 'jobpost';
    this.router.navigate(['/pharmacy/jobpost']);
  }
  viewhire() {
    this.activeTab = 'hire';
    this.router.navigate(['/pharmacy/hire']);
  }
  wallet() {
    this.activeTab = 'wallet';
    this.router.navigate(['/pharmacy/wallet']);
  }
  viewprofile() {
    this.activeTab = 'Profile Settings';
    this.router.navigate(['/pharmacy/settings/UserProfile']);

  }
  viewContract() {
    this.activeTab = 'contract';
    this.router.navigate(['/pharmacy/contracts']);
  }
  viewUser() {
    this.activeTab = 'user';
    this.router.navigate(['/pharmacy/user']);
  }
  setting() {
    // this.activeTab = 'Settings';
    this.show = !this.show
  }
  view(key) {
    this.activeTab = key;
    this.router.navigate(['/pharmacy/' + key.toLowerCase()]);
  }
  viewChat() {
    this.activeTab = 'chat';
    this.router.navigate(['/pharmacy/chat']);
  }



}
