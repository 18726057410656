import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../app.settings';
import { Observable, throwError, from, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Headers, RequestOptions, Request, RequestMethod } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private http: HttpClient) {}

  public getlistwallet(userreferencid, page): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.PHARMACYTRASCATION +
        userreferencid +
          `?limit=9&offset=${(page - 1) * 9}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.errorHandler)
      );
  }
  

  public getwalletpoint(userreferencid): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.GETWALLETPOINT +
        userreferencid  )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.errorHandler)
      );
  }

  Createredeemevoucher(data) {
    return this.http
      .post<any>(AppSettings.apiurls.REDEEMEDVOUCHER, { ...data })
      .pipe(
        map((voucher) => {
       
          return voucher;
        }),
        catchError(this.errorHandler)
      );
  }


   errorHandler(error: any) {
    console.log('Error--->', error);
    return throwError(error);
  }


}
