import { Component, OnInit } from '@angular/core';
import { SuperadmindashboardService } from '../superadmindashboad/superadmindashboard.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
// import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
// import Swal from 'sweetalert2';
import {ToastrService} from '../../../toastr.service'
import { NgxSpinnerService } from "ngx-spinner";
import { JsonPipe } from '@angular/common';

@Component({
  selector: 'app-superadmindashboad',
  templateUrl: './superadmindashboad.component.html',
  styleUrls: ['./superadmindashboad.component.css']
})
export class SuperadmindashboadComponent implements OnInit {
  public errorMessage: any
  public Count=[];
 constructor(
    private dashboard: SuperadmindashboardService,
    private router: Router,
    private tosterservice:ToastrService,
    private spinner : NgxSpinnerService
  ) {}
  ngOnInit(): void {
    let role = JSON.parse(localStorage.getItem('currentUser')).data?.role
    if(role===1){
      this.getdasboarddata()
    }
    else{
      this.router.navigate(['/login']);

    }
  }
 getdasboarddata(): void {
  this.spinner.show();

    this.dashboard
      .getSuperadminDashboardData()
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data) {
            this.errorMessage = '';
            console.log(data.data);
            this.Count=data.data
            this.spinner.hide();

         
          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }
}
