<div class="content-wrapper" >
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mx-2">
          <div class="col-6">
              <h3>User</h3>
            </div>
          <div class="col-6">
            <!-- <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">User</li>
            </ol> -->
            <div style="float: right;">
              <button class="btn btnn" (click)='adnewuser()'>Add New User</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="content">
      <div class="col-12">
        <div class="row">
          <div class="col-12" >
                <div class="card" *ngIf='sabuserlist.length !== 0' > 
                  <div class="card-body">
                          <div class="col-12">
                            <div class="row pb-4 " *ngFor="let item of sabuserlist" >
                              <div class="col-1 "> 
                                <!-- <input class="mr-3" type="radio"/> -->
                                <img
                                src="../../../../assets/images/female-pharmacist-home.jpg"
                                width="60px "
                                height="60px"
                                style="border-radius:50%"
                              /></div>
  
                             
                              <div class="col-3 pt-2">
                                <p class="">
                                 <b>{{ item.firstname }}  {{ item.lastname }}
                                </b> 
                                </p>
                              </div>
                              <div class="col-4 pt-2"><p class="">{{item.email}}</p></div>
                             
                              <div  class="col-2 pt-2"><p class="">{{item.phone}}</p></div>
          <div class="col-1  pt-1">
            <button class="btn btnnn " (click)='edituser(item.userReferenceID,item)'>Edit</button>
          </div>
          <div class="col-1  pt-1">
            <button class="btn delete" (click)='Delete(item.userReferenceID,item)'>Delete</button>
          </div>
                           </div>
                           <div  *ngIf='sabuserlist.length !== 0'>    
                              <ngb-pagination
                            [collectionSize]="collectionsize"
                            [(page)]="page"
                            (pageChange)="reloadDataa()"
                            [pageSize]="10"
                            [maxSize]="4"
                            [boundaryLinks]="true"
                          ></ngb-pagination>
                        </div>
                          </div>
                  </div><!-- /.card-body -->
                  
                </div>
  
                <div class="card p-3" style="width: 100%;
                  height: 550px;"   *ngIf='sabuserlist.length === 0' >
                    <div class="card-body">
                      <div class=" text-center" >
                        <!-- <p class="text-center" style="font-size: 25px;">
                          No Records
                        </p> -->
                        <img src="../../../assets/images/nodata.jpg" height="350px"/>
                        <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories - www.freepik.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
          </div>
        
        </div>
      </div>
    </section>
  </div> 

<!-- Editmodal -->
  <div class="modal" id="myModal" [ngClass]="editmodalShow ? 'success' : 'black'" role="dialog" data-backdrop="false">
    <div class="modal-dialog">
      <div class="modal-content" style="width: 700px;">
     <div class="col-12">
    <div class="row">
        <div class="col-6 ">
          <img src="../../../../assets//images/Rectangle 1628.png" width="100%" style="margin-left: -8px;"/>
        </div>
        <div class="col-6">
          <div class="float-right" style="cursor: pointer;" (click)="editmodalHide()"><i class="fa fa-times " aria-hidden="true"></i></div>
            <form [formGroup]="editformVar" class="pt-5 " (ngSubmit)="onSubmit()"  >
                <div class="card-body">
                                    <div class="form-group ">
                                        <label for="exampleFormControlTextarea1"
                                          >First Name</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          formControlName="firstname"
                                          (keypress)='onlyText($event)'

                                         
                                        />
                                     
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1"
                                          >Last Name</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          formControlName="lastname"
                                          (keypress)='onlyText($event)'
                    
                                        
                                        />
                                     
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1"
                                          >Phone Number</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          formControlName="phone"
                                          (keypress)='onlyNumber($event)'
                                          maxlength="10"
                                         
                                        />
                                    
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1"
                                          >Email</label
                                        >
                                        <input
                                          type="email"
                                          class="form-control"
                                          formControlName="email" />
                                   
                                      </div>
                                <div><label>Section</label></div>
                                <div class="row">
                                  <div class="col-6" *ngFor="let category of sectionconst;let i = index">
                                    <div style="font-size: 14px;" >
                                      <input type="checkbox" [name]="category?.key" [formControl]="editformVar.controls['myCategory'].controls[i]
                                                                                              " 
                                        [value]="category?.key" [id]="category?.key" />
                                      {{category?.key}}
                                    </div>
                                  </div>
                              
                                </div>
                                <div *ngIf='errorMessage!==null' class="mt-2">
                                  <p class="text-danger">{{errorMessage}}</p>
                                </div>
                             <div class="row">
                              <div class="col-3"></div>                             
                            <div class=" col-6 mt-3" style="    text-align: center;">
                              <button type="submit" class="btnn ">Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                              <br />
                            </div>  
                            <div class="col-3"></div>                             
                            </div>

                         </div>           
                 

                <!-- /.card-body -->
              
              </form>
        </div>
    </div>
</div>       
      </div>
    </div>
  </div>

  <!-- withouteditmodal -->
 <div class="modal" id="myModal" [ngClass]="modalShow ? 'success' : 'black'" role="dialog" data-backdrop="false">
    <div class="modal-dialog">
      <div class="modal-content" style="width: 700px;">
     <div class="col-12">
    <div class="row">
        <div class="col-6 ">
          <img src="../../../../assets//images/Rectangle 1628.png" width="100%" style="margin-left: -8px;"/>
        </div>
        <div class="col-6">
          <div class="float-right" style="cursor: pointer;" (click)="modalHide()"><i class="fa fa-times " aria-hidden="true"></i></div>
            <form [formGroup]="formVar" class="pt-5 "   (ngSubmit)="submit()">
                <div class="card-body">
                                    <div class="form-group ">
                                        <label for="exampleFormControlTextarea1"
                                          >First Name</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          formControlName="firstname"
                                          (keypress)='onlyText($event)'

                                          [ngClass]="{
                                            'is-invalid': submitted && f.firstname.errors
                                          }"
                                        />
                                        <div
                                          *ngIf="submitted && f.firstname.errors"
                                          class="invalid-feedback"
                                        >
                                          <div *ngIf="f.firstname.errors.required">
                                            first name is required
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1"
                                          >Last Name</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          formControlName="lastname"
                                          (keypress)='onlyText($event)'
                    
                                          [ngClass]="{
                                            'is-invalid': submitted && f.lastname.errors
                                          }"
                                        />
                                        <div
                                          *ngIf="submitted && f.lastname.errors"
                                          class="invalid-feedback"
                                        >
                                          <div *ngIf="f.lastname.errors.required">
                                            Last name is required
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1"
                                          >Phone Number</label
                                        >
                                        <input
                                          type="text"
                                          class="form-control"
                                          formControlName="phone"
                                          (keypress)='onlyNumber($event)'
                                          maxlength="10"
                                          [ngClass]="{
                                            'is-invalid': submitted && f.phone.errors
                                          }"
                                        />
                                        <div
                                          *ngIf="submitted && f.phone.errors"
                                          class="invalid-feedback"
                                        >
                                          <div *ngIf="f.phone.errors.required">
                                        Phone number is required
                                          </div>
                                          <div *ngIf="f.phone.errors.pattern">
                                            Phone No must be a 10 digit number
                                          </div>
                                        </div>
                                      </div>
                                      <div class="form-group">
                                        <label for="exampleFormControlTextarea1"
                                          >Email</label
                                        >
                                        <input
                                          type="email"
                                          class="form-control"
                                          formControlName="email"
                                          [ngClass]="{
                                            'is-invalid': submitted && f.email.errors
                                          }"
                                                                          />
                                        <div
                                          *ngIf="submitted && f.email.errors"
                                          class="invalid-feedback"
                                        >
                                          <div *ngIf="f.email.errors.required">
                                        Email is required
                                          </div>
                                          <div *ngIf="f.email.errors.pattern">
                                            Must be a valid email address
                                          </div>
                                        </div>
                                      </div>
                                <div><label>Section</label></div>
                                <div class="row">
                                  <div class="col-6" *ngFor="let category of sectionconst;let i = index">
                                    <div style="font-size: 14px;" >
                                      <input type="checkbox" [name]="category?.key" [formControl]="formVar.controls['myCategory'].controls[i]
                                                                                              " 
                                        [value]="category?.key" [id]="category?.key" />
                                      {{category?.key}}
                                    </div>
                                  </div>
                               
                                </div>
                                <div *ngIf='errorMessage!==null' class="mt-2">
                                  <p class="text-danger">{{errorMessage}}</p>
                                </div>
                             <div class="row">
                              <div class="col-3"></div>                             
                            <div class=" col-6 mt-3" style="    text-align: center;">
                              <button type="submit" class="btnn " >Save</button>&nbsp;&nbsp;&nbsp;&nbsp;
                            
                              <br />
                            </div>  
                            <div class="col-3"></div>                             
                            </div>

                         </div>                        
              </form>
        </div>
    </div>
</div>       
      </div>
    </div>
  </div> 