import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../app.settings';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError, from, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authenticationId: string;
  public jwtHelper = new JwtHelperService();

  constructor(private http: HttpClient) { }
  errorHandler(error: any) {
    console.log('Error--->', error);
    return throwError(error);
  }
  // --------------------------signup------------------------------------------------//
  signup(data) {
    return this.http
      .post<any>(AppSettings.apiurls.USERSIGNUP, { ...data })
      .pipe(
        map((createdUser) => {
          if (createdUser.data) {
            localStorage.setItem('signupUserReferenceId', createdUser.data.referenceId);
          }
          return createdUser;
        }),
        catchError(this.errorHandler)
      );
  }

  // --------------------------Login------------------------------------------------//
  login(data) {
    return this.http
      .post<any>(AppSettings.apiurls.USERSIGNIN, { ...data })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('signupUserReferenceId', user.data.referenceId);
          localStorage.setItem('accessToken', user.data.accessToken);
          // this.currentUserSubject.next(user);
          return user;
        }),
        catchError(this.errorHandler)
      );
  }

  //-----------------------------Forgotpassword----------------------------------------------//
  forgotpassword(data) {
    return this.http
      .post<any>(AppSettings.apiurls.FORGOTPASSWORD, { ...data })
      .pipe(
        map((email) => {
          // console.log('---print--- ' + email);
          // console.log('access token in signn :' + email.accessToken);
          // console.log(email.refreshToken);
          // console.log(email.referenceId);
          localStorage.setItem('signupUserReferenceId', email.referenceId);
        }),
        catchError(this.errorHandler)
      );
  }

  generateCode(referenceId) {
    const token = localStorage.getItem('accessToken');
    return this.http
      .post<any>(AppSettings.apiurls.GENERATECODE, { referenceId })
      .pipe(
        map((createdUser) => {
          return JSON.stringify(createdUser);
        }),
        catchError(this.errorHandler)
      );
  }

  VerifyCode(referenceId, code) {
    const token = localStorage.getItem('accessToken');
    return this.http
      .post<any>(AppSettings.apiurls.VERIFYCODE, { referenceId, code })
      .pipe(
        map((createdUser) => {

          localStorage.setItem('accessToken', createdUser.accessToken);
          return JSON.stringify(createdUser);
        }),
        catchError(this.errorHandler)
      );
  }

  CreatePharmacyUser(pharmacyUserData) {

    return this.http
      .post<any>(AppSettings.apiurls.CREATEPHARMACYUSER, pharmacyUserData)
      .pipe(
        map((createdPharmacyUser) => {

          return JSON.stringify(createdPharmacyUser);
        }),
        catchError(this.errorHandler)
      );
  }

  CreatePharmacy(pharmacyArray) {
    // console.log(pharmacyArray);
    return this.http
      .post<any>(AppSettings.apiurls.CREATEPHARMACY, pharmacyArray)
      .pipe(
        map((createdPharmacy) => {
          // console.log(
          //   'created Pharmacy Data--------->',
          //   JSON.stringify(createdPharmacy)
          // );
          return JSON.stringify(createdPharmacy);
        }),
        catchError(this.errorHandler)
      );
  }

  GetPharmacyListByAuthId() {
    // console.log('GetPharmacyListByAuthId:');
    this.authenticationId = localStorage.getItem('signupUserReferenceId');
    // console.log('auth.service.ts ---before api call');
    // console.log(AppSettings.apiurls.GETPHARMACYDETAILSBYAUTHID);
    return this.http
      .get<any>(
        AppSettings.apiurls.GETPHARMACYDETAILSBYAUTHID +
        '/' +
        this.authenticationId
      )
      .pipe(
        map((pharmacylist) => {
          // console.log('Pharmacy Data from api--------->', pharmacylist);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // this.currentUserSubject.next(user);
          return pharmacylist;
        }),
        catchError(this.errorHandler)
      );
  } //end api func

  GetPharmacyUserByAuthId() {
    // console.log('Auth.service : GetPharmacyUserByAuthId:');
    this.authenticationId = localStorage.getItem('signupUserReferenceId');
    // console.log('auth.service.ts ---before api call');
    // console.log(AppSettings.apiurls.GETPHARMACYUSERBYAUTHID);
    return this.http
      .get<any>(
        AppSettings.apiurls.GETPHARMACYUSERBYAUTHID + this.authenticationId
      )
      .pipe(
        map((pharmacyUser) => {
          // console.log('Pharmacy User Data from api--------->', pharmacyUser);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          // this.currentUserSubject.next(user);
          return pharmacyUser;
        }),
        catchError(this.errorHandler)
      );
  }
  Getmembershipbystate(state) {
    // console.log('Auth.service : GetPharmacyUserByAuthId:');
    this.authenticationId = localStorage.getItem('signupUserReferenceId');
    // console.log('auth.service.ts ---before api call');
    // console.log(AppSettings.apiurls.GETMEMBERSHIP);
    return this.http.get<any>(AppSettings.apiurls.GETMEMBERSHIP + state).pipe(
      map((pharmacyUser) => {
        // console.log('Pharmacy User Data from api--------->', pharmacyUser);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // this.currentUserSubject.next(user);
        return pharmacyUser;
      }),
      catchError(this.errorHandler)
    );
  }

  GetSubscriptionPlans(type) {
    // console.log('Auth.service : GetPharmacyUserByAuthId:');
    this.authenticationId = localStorage.getItem('signupUserReferenceId');
    // console.log('auth.service.ts ---before api call');
    // console.log(AppSettings.apiurls.GETMEMBERSHIP);
    return this.http.get<any>(AppSettings.apiurls.GETSUBSCRIPTIONPLANS + type).pipe(
      map((pharmacyUser) => {
        // console.log('Pharmacy User Data from api--------->', pharmacyUser);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // this.currentUserSubject.next(user);
        return pharmacyUser;
      }),
      catchError(this.errorHandler)
    );
  }

  GetSubscription() {    
    //this.authenticationId = localStorage.getItem('signupUserReferenceId');    
    return this.http.get<any>(AppSettings.apiurls.GETSUBSCRIPTIONDETAILS).pipe(
      map((pharmacyUser) => {
        return pharmacyUser;
      }),
      catchError(this.errorHandler)
    );
  }

  updateMembershipid(membershipid, referenceIdd) {
    // console.log(membershipid);
    // console.log(AppSettings.apiurls.PHARMACYMEMBERSHIPBYID + referenceIdd);
    return this.http
      .put<any>(
        AppSettings.apiurls.PHARMACYMEMBERSHIPBYID + referenceIdd,
        membershipid
      )
      .pipe(
        map((membership) => {
          // console.log('Membership id--------->', JSON.stringify(membership));
          return JSON.stringify(membership);
        }),
        catchError(this.errorHandler)
      );
  }

  GetStripeKey() {
    // console.log('Auth.service : GetStripeKey:');
    // console.log('auth.service.ts ---before api call');
    // console.log(AppSettings.apiurls.PAYMENTDETAILS);
    return this.http.get<any>(AppSettings.apiurls.PAYMENTDETAILS).pipe(
      map((keyy) => {
        // console.log('GetStripeKey--------->', keyy);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // this.currentUserSubject.next(user);
        return keyy;
      }),
      catchError(this.errorHandler)

    );
  }

  Createcustomer(customerdetails) {
    // console.log('create pharmacy api call');
    // console.log(customerdetails);
    return this.http
      .post<any>(AppSettings.apiurls.CREATECUSTOMER, customerdetails)
      .pipe(
        map((customerinfo) => {
          // console.log(
          //   'created Pharmacy Data--------->',
          //   JSON.stringify(customerinfo)
          // );
          return JSON.stringify(customerinfo);
        }),
        catchError(this.errorHandler)
      );
  }
  Createcustomerach(customerdetails) {
    // console.log('create pharmacy api call');
    // console.log(customerdetails);
    return this.http
      .post<any>(AppSettings.apiurls.CREATECUSTOMER, customerdetails)
      .pipe(
        map((customerinfo) => {
          // console.log(
          //   'created Pharmacy Data--------->',
          //   JSON.stringify(customerinfo)
          // );
          return JSON.stringify(customerinfo);
        }),
        catchError(this.errorHandler)
      );
  }

  Resetpassword(token) {
    return this.http.get<any>(AppSettings.apiurls.RESETPASSWORD + token).pipe(
      map((pass) => {
        // console.log('Pharmacy User Data from api--------->', pass);
        return pass;
      }),
      catchError(this.errorHandler)
    );
  }

  GetStripeCustomer() {
    return this.http.get<any>(AppSettings.apiurls.GETCUSTOMER).pipe(
      map((pass) => {        
        return pass;
      }),
      catchError(this.errorHandler)
    );
  }


  Resetpasswordform(token, data) {
    return this.http
      .post<any>(AppSettings.apiurls.RESETPASSWORDFORM + token, { ...data })
      .pipe(
        map((createdUser) => {
          // console.log(
          //   'User Data in verifycode--------->',
          //   JSON.stringify(createdUser)
          // );
          // localStorage.setItem('accessToken', createdUser.accessToken);
          return createdUser
        }),
        catchError(this.errorHandler)
      );
  }

  GetpharmacysubuserbyAuthId(auth) {
    return this.http.get<any>(AppSettings.apiurls.GETSUBUSERBYAUTHONTICATIONID + auth).pipe(
      map((pass) => {
        console.log('Pharmacy User Data from api--------->', pass);
        localStorage.setItem('signupUserReferenceId', pass?.data[0].pharmacyAuthenticationId)
        localStorage.setItem('subuserpharmacyuserid', pass?.data[0].pharmacyUserReferenceId)


        return pass;
      }),
      catchError(this.errorHandler)
    );
  }

  changepassword(data, authonticationid) {
    // console.log(AppSettings.apiurls.CHANGEPASSWORD + authonticationid);
    return this.http
      .put<any>(
        AppSettings.apiurls.CHANGEPASSWORD + authonticationid,
        data
      )
      .pipe(
        map((changepassworddata) => {
          // console.log('changepassword --------->', JSON.stringify(changepassworddata));
          return JSON.stringify(changepassworddata);
        }),
        catchError(this.errorHandler)
      );
  }

  UpdateSubscriptionPlan(priceId, stripeSubscriptionId) {

    return this.http
      .put<any>(AppSettings.apiurls.UPDATESUBSCRIPTIONPLAN + stripeSubscriptionId, {priceId: priceId})
      .pipe(
        map((createdPharmacyUser) => {

          return JSON.stringify(createdPharmacyUser);
        }),
        catchError(this.errorHandler)
      );
  }
  
  CancelSubscriptionPlan(stripeSubscriptionId) {

    return this.http
      .delete<any>(AppSettings.apiurls.CANCELSUBSCRIPTIONPLAN + stripeSubscriptionId)
      .pipe(
        map((createdPharmacyUser) => {

          return JSON.stringify(createdPharmacyUser);
        }),
        catchError(this.errorHandler)
      );
  }

  BuySubscriptionPlan(priceId, stripecustomerId) {

    return this.http
      .post<any>(AppSettings.apiurls.CREATESUBSCRIPTIONPLAN , {stripecustomerId: stripecustomerId, stripePriceId: priceId})
      .pipe(
        map((createdPharmacyUser) => {

          return JSON.stringify(createdPharmacyUser);
        }),
        catchError(this.errorHandler)
      );
  }
}
