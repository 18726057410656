import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { throwError, from, Subject } from 'rxjs';
import { delay } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  token: any;
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //console.log('Inside interceptor');
    this.token = localStorage.getItem('accessToken');
    //console.log('Token---->', this.token);
    request = request.clone({
      setHeaders: { Authorization: `Bearer ${this.token}` },
    });
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          localStorage.removeItem('pharmacyUser');
          localStorage.removeItem('currentUser');
          localStorage.removeItem('signupUserReferenceId');
          localStorage.removeItem('accessToken');
          localStorage.removeItem('pharmacyUserId');
          localStorage.removeItem('pharmacylist');
          localStorage.removeItem('joblistvalue')
          this.router.navigate(['/']);

        }
        const error = err.error.message || err.statusText;
        return throwError(error);
      })
    );
  }
}