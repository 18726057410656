import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.css']
})
export class AuthLayoutComponent implements OnInit {
public show= true
  constructor() { }

  ngOnInit(): void {
    // this.screen_resize()
  }
  showsidebar() {
    var h = Number(window.innerHeight);
    var w = Number(window.innerWidth);

    if(w <= 500) {
        //max-width 500px
        // actions here...
        this.show= false

      }
       else if(w > 500 && w <=900) {
        //max-width 850px
        // actions here...
        this.show= false


    } else {
        // 850px and beyond
        // actions here...
    }

}


}
