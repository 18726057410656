<div class="content-wrapper">
  <!-- <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">Dashboard</h1>
        </div>
        <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Dashboard</li>
          </ol>
        </div>
      </div>
    </div>
  </div> -->
  <section class="content">

    <div class="row">
      <div class="col-12">
        <div>
          <div class="py-4">

            <h3 class="">My Postings</h3>
          </div>
          <div class="card" *ngFor="let key of Object.keys(jobListing)">

            <div class="card-body">
              <div class="row ">

                <div class="col-2">
                  <div>
                    <p><b class="postingname" (click)="
                                viewjobdetals(
                                  jobListing[key].jobReferenceId,
                                  jobListing[key].jobId
                                )
                              ">{{ jobListing[key].jobName }}</b><br>{{ jobListing[key].createdAt | date: short }}</p>
                  </div>
                </div>
                <div class="col-2">
                  <div>
                    <p>{{ jobListing[key].proposal }}<br />Proposal</p>
                  </div>
                </div>
                <div class="col-2">
                  <div>
                    <p>{{ jobListing[key].invited }}<br />Invited</p>
                  </div>
                </div>
                <div class="col-2">
                  <div>
                    <p>{{ jobListing[key].hired }}<br />Hired</p>
                  </div>
                </div>
                <div class="col-2">
                  <div class="pr-5 pt-4" style="display: flex;">
                    <p *ngIf="jobListing[key].status==='active'" style="color: green;"><b>ACTIVE </b>
                    </p>
                    <p *ngIf="jobListing[key].status==='expired'" style="color: red;"><b>EXPIRED </b>
                    </p>
                    <p *ngIf="jobListing[key].status==='completed'" style="color: green;"><b>COMPLETED</b>
                    </p>
                    <p *ngIf="jobListing[key].status==='completed'" style="color: green; margin-left: 10px;">
                      <b>
                        <a href="invoice/{{jobListing[key].pharmacistId}}/{{jobListing[key].contractId}}"
                          target="_blank">
                          <i class="fa fa-download"></i>
                        </a>
                      </b>
                    </p>
                    <p *ngIf="jobListing[key].status==='draft'" style="color: blue;"><b>DRAFT</b></p>
                  </div>
                </div>
                <div class="col-2">
                  <div class=" d-flex">
                    <div class=" d-flex" data-widget="treeview" role="menu" data-accordion="false">
                      <div class=" d-flex">

                        <div class=" d-flex" style="padding-top:23px;" *ngIf='clickedid === jobListing[key].jobId'>
                          <div class="nav-item">
                            <a class="nav-link">
                              <p (click)="
                                viewjobdetals(
                                  jobListing[key].jobReferenceId,
                                  jobListing[key].jobId
                                )
                              "><i class="fa fa-eye" title="View Job Post" aria-hidden="true"
                                  style="color: #00A3FF;cursor: pointer;"></i>
                              </p>
                            </a>
                          </div>
                          <div class="nav-item" *ngIf="jobListing[key].status==='active'">
                            <a class="nav-link">
                              <p (click)="
                                editjobdetails(jobListing[key].jobReferenceId)
                              "><i class="fa fa-pencil-square-o" title="Edit Job Post" aria-hidden="true" style="color: #00A3FF;cursor: pointer;
        ;"></i>
                              </p>
                            </a>
                          </div>
                          <div class="nav-item">
                            <a class="nav-link">
                              <p (click)="deletejobdetalis(jobListing[key].jobReferenceId)"><i class="fa fa-trash"
                                  aria-hidden="true" title="Delete Job Post"
                                  style="color: #FF2E2E;cursor: pointer;"></i>
                              </p>
                            </a>
                          </div>

                        </div>
                        <a class="nav-link">
                          <i class="nav-icon fa fa-tachometer-alt"></i>

                          <p>

                            <i title="View" style="color: #00A3FF;cursor: pointer;
        ;" [id]="jobListing[key].jobId" class="fa fa-ellipsis-h" *ngIf="
                                                                   clickedid !== jobListing[key].jobId"
                              (click)='openclose(jobListing[key].jobId)'></i>

                            <i title="Close" style="cursor: pointer;" [id]="jobListing[key].jobId" class="fa fa-times"
                              *ngIf="clickedid === jobListing[key].jobId "
                              (click)='closeopen(jobListing[key].jobId)'></i>
                          </p>
                        </a>
                      </div>



                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>
          <div class="card p-3" style="width: 100%;
                              height: 550px;" *ngIf=' Object.keys(jobListing).length === 0'>
            <div class="card-body">
              <div class=" text-center">
                <!-- <p class="text-center" style="font-size: 25px;">
                    No Records
                  </p> -->
                <img src="../../../assets/images/nodata.jpg" height="350px" />
                <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories - www.freepik.com</a>
                </div>
              </div>
            </div>
          </div>
          <!-- <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" (pageChange)="reloadData()" [pageSize]="10"
          [maxSize]="4" [boundaryLinks]="true"></ngb-pagination> -->
          <p *ngIf=' Object.keys(jobListing).length !== 0' class="showall" (click)='showalldata()'>View All</p>
        </div>


        <div>
          <div class="py-4">
            <h3 class="sheduled">Scheduled Pharmacist</h3>


          </div>


          <div class="card pt-0" *ngFor="let item of contracts">

            <div class="card-body">
              <div class="col-12">
                <div class="pb-0"><b class="shedulename" (click)='showallallhire()'>{{item.contractTitle}}</b>
                  <p style="margin-top: -5px;" class="pl-0">{{ item.createdDate | date: short }}</p>

                </div>
                <div class="row">

                  <div class="col-1">
                    <div *ngIf="item.profileImageUrl; else noImageTemplate">
                      <img src="{{s3_arn}}{{item.profileImageUrl}}" width="70px " height="70px"
                        style="border-radius: 50%;" />
                    </div>

                    <ng-template #noImageTemplate>
                      <img src="../../../assets/images/placeholder-image.png" width="70px " height="70px"
                        style="border-radius: 50%;" />
                    </ng-template>
                  </div>
                  <div class="col-3 text-center">
                    <p><b class="hirepharmacistname" (click)='showallallhire()'>{{ item.pharmacistName }}</b><br />
                    </p>
                    <div class="text-center showstar">
                      <div class="d-flex  ">
                        <div *ngFor='let in of counter(toNumber(item.ratings)) ;let i = index'>
                          <i class="fa fa-star" aria-hidden="true" style="color: #ffc107;"></i>
                        </div>
                        <div *ngFor='let in of counter(5-toNumber(item.ratings)) ;let i = index'><i class="fa fa-star-o"
                            aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 text-center">
                    <p>starts on:<br />{{ item.finalizedDateTime | date: short }}</p>
                  </div>
                  <div class="col-3 text-center">
                    <p>Expected hours :<br />{{item.expectedhours }}</p>
                  </div>
                  <div class="col-2">
                    <div class="text-center" *ngIf="item.jobPostStatus != 'completed'">
                      <button class="btnn btn text-center"
                        (click)='modalHideShoww(item.pharmacistName , item.finalizedDateTime,item.finalizedAmount,item.contractReferenceId,item.contractTitle,item.expectedhours )'>Cancel
                        hire</button>
                      <p class="text-center">${{item.finalizedAmount}}</p>
                    </div>

                  </div>


                </div>
              </div>
            </div>
          </div>
          <div class="card p-3" style="width: 100%;
                      height: 550px;" *ngIf=' contracts.length === 0'>
            <div class="card-body">
              <div class=" text-center">
                <!-- <p class="text-center" style="font-size: 25px;">
            No Records
          </p> -->
                <img src="../../../assets/images/nodata.jpg" height="350px" />
                <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories - www.freepik.com</a>
                </div>
              </div>
            </div>
          </div>
          <!-- <ngb-pagination [collectionSize]="collectionsizecontract" [(page)]="page" (pageChange)="reloadDataa()" [pageSize]="10"
          [maxSize]="4" [boundaryLinks]="true"></ngb-pagination> -->
          <p *ngIf=' contracts.length !== 0' class="showall" (click)='showallallhire()'>View All</p>

        </div>

      </div>
    </div>
  </section>
</div>


<div class="modal" id="myModal" [ngClass]="modalShow ? 'success' : 'black'" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="">
        <div class="pl-3 pt-2">
          <div style="cursor: pointer;">
            <h4 (click)="modalHide()"><i class="fa fa-angle-left px-2 py-1" title="Go Back" aria-hidden="true" style="background: #C4C4C4; 
border-radius: 8px;"></i>
            </h4>
          </div>
        </div>
      </div>
      <div class="modal-body" style="    margin-top: -25px;">
        <div>
          <div class="text-center"><img src="../../../../assets/images/female-pharmacist-home.jpg" class="img"
              width="70px " height="70px" style="border-radius: 50%;" />
            <p style="font-size: 18px;"><b>{{name}}</b></p>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-8 px-4">
                <p>Date of Hire &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{datetime | date: "MMM d, y"}}</p>
                <p>Time of Hire &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datetime | date: "shortTime"}}</p>
                <p>Approximate Cost &nbsp;&nbsp;&nbsp;&nbsp; ${{finalcost}}</p>

              </div>
              <div class="col-4 float-right">
                <div style="background: #00A3FF;
border-radius: 6px;">
                  <p class="text-white text-center" style="font-size: 20px;"><b>{{expextedhour}} H</b></p>
                  <p class="text-white text-center" style="margin-top: -5px;">Total hours</p>
                </div>
              </div>
            </div>


            <!-- <div class="text-center mt-4">
              <p style="font-size: 18px;">Cancellation Charge &nbsp;&nbsp;<span style="background: #C4C4C4; padding: 7px;
border-radius: 8px;">$5</span></p>
            </div> -->
            <!-- /.card-body -->
            <div class="row">
              <div class="col-3"></div>
              <div class="col-6">
                <button type="submit" class="btn bttnn" (click)='submit()'>Cancel Schedule</button>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
          <!-- <button style="width: 75px;" type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="modalHideShoww()">
                Close</button><br /> -->
        </div>
      </div>
    </div>
  </div>
</div>