import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../app.settings';
import { Observable, throwError, from, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Headers, RequestOptions, Request, RequestMethod } from '@angular/http';
import { replace } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class JobpostService {
  constructor(private http: HttpClient) {}

  sideBarObject = new Subject<object>();

  CreateJobPost(data) {
    return this.http
      .post<any>(AppSettings.apiurls.CREATEJOBPOST, { ...data })
      .pipe(
        map((createdJoobpost) => {
          // console.log(
          //   'Pharmacy User Data--------->',
          //   JSON.stringify(createdJoobpost)
          // );
          return JSON.stringify(createdJoobpost);
        }),
        catchError(this.errorHandler)
      );
  }

  updateJobPost(jobpostData, jobpostId) {
    // console.log(AppSettings.apiurls.UPDATEJOBPOST + jobpostId);
    return this.http
      .put<any>(AppSettings.apiurls.UPDATEJOBPOST + jobpostId, jobpostData)
      .pipe(
        map((updateJobpost) => {
          // console.log(
          //   'Pharmacy User Data--------->',
          //   JSON.stringify(updateJobpost)
          // );
          return JSON.stringify(updateJobpost);
        }),
        catchError(this.errorHandler)
      );
  }

  public getPharmacyJoblisting(pharmacyRefrenceId, page): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.GETPHARMACYJOBLISTING +
          pharmacyRefrenceId +
          `/joblisting?limit=10&offset=${(page - 1) * 10}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.errorHandler)
      );
  }

  getJobPost(jobpostId) {
    return this.http
      .get<any>(AppSettings.apiurls.GETJOBPOSTBYID + jobpostId)
      .pipe(
        map((getJobpost) => {
          // console.log(
          //   'Pharmacy User Data--------->',
          //   JSON.stringify(getJobpost)
          // );
          return JSON.stringify(getJobpost);
        }),
        catchError(this.errorHandler)
      );
  }
    getjobpostbasedonpharmacyuserid(pharmacyuserid) {
    return this.http
      .get<any>(AppSettings.apiurls.LISTJOBPOSTBASEDONPHARMACYUSERID + pharmacyuserid)
      .pipe(
        map((getJobpost) => {
          // console.log(
          //   'Pharmacy User Data--------->',
          //   JSON.stringify(getJobpost)
          // );
          return JSON.stringify(getJobpost);
        }),
        catchError(this.errorHandler)
      );
  }

  getPharmacyListByAuthenticationId(authenticationsId) {
    return this.http
      .get<any>(AppSettings.apiurls.LISTPHARMACY + authenticationsId)
      .pipe(
        map((pharmacy) => {
          return JSON.stringify(pharmacy);
        }),
        catchError(this.errorHandler)
      );
  }

  SearchPharmacist(joblistreferenceid, page, search) {
    return this.http
      .get<any>(
        AppSettings.apiurls.SEARCHPHARMACIST +
          joblistreferenceid +
          `?limit=10&offset=${(page - 1) * 10}&search=${search}`
      )
      .pipe(
        map((getjobdetails) => {
          // console.log(
          //   'pharmacist Data--------->',
          //   JSON.stringify(getjobdetails)
          // );
          return JSON.stringify(getjobdetails);
        }),
        catchError(this.errorHandler)
      );
  }

  SavedPharmacist(data) {
    return this.http
      .post<any>(AppSettings.apiurls.SAVEPHARMACIST, { ...data })
      .pipe(
        map((savedpharmacist) => {
          // console.log(
          //   'Pharmacy User Data--------->',
          //   JSON.stringify(savedpharmacist)
          // );
          return JSON.stringify(savedpharmacist);
        }),
        catchError(this.errorHandler)
      );
  }

  SavedPharmacistList(joblistreferenceid, page) {
    return this.http
      .get<any>(
        AppSettings.apiurls.SAVEDPHARMACISTLIST +
          joblistreferenceid +
          `?limit=10&offset=${(page - 1) * 10}`
      )
      .pipe(
        map((getjobdetails) => {
          // console.log(
          //   'pharmacist Data--------->',
          //   JSON.stringify(getjobdetails)
          // );
          return JSON.stringify(getjobdetails);
        }),
        catchError(this.errorHandler)
      );
  }

 Updatepharmacist(data, jobpostId) {
    return this.http
      .put<any>(AppSettings.apiurls.UPDATESAVEDPHARMACIST + jobpostId, data)
      .pipe(
        map((updatepharmacist) => {
          // console.log(
          //   'Pharmacy User Data--------->',
          //   JSON.stringify(updatepharmacist)
          // );
          return JSON.stringify(updatepharmacist);
        }),
        catchError(this.errorHandler)
      );
  }


  InvitedPharmacist(data) {
    return this.http
      .post<any>(AppSettings.apiurls.INVITEPHARMACIST, { ...data })
      .pipe(
        map((invitedpharmacist) => {
          // console.log(
          //   'Pharmacy User Data--------->',
          //   JSON.stringify(invitedpharmacist)
          // );
          return JSON.stringify(invitedpharmacist);
        }),
        catchError(this.errorHandler)
      );
  }

  InvitedPharmacistList(joblistreferenceid, page) {
    return this.http
      .get<any>(
        AppSettings.apiurls.INVITEDPHARMACIST +
          joblistreferenceid +
          `?limit=10&offset=${(page - 1) * 10}`
      )
      .pipe(
        map((getinvitephamacist) => {
          // console.log(
          //   'pharmacist Data--------->',
          //   JSON.stringify(getinvitephamacist)
          // );
          return JSON.stringify(getinvitephamacist);
        }),
        catchError(this.errorHandler)
      );
  }

  Myhiredpharmacist(joblistreferenceid, page) {
    return this.http
      .get<any>(
        AppSettings.apiurls.MYHIRE +
          joblistreferenceid +
          `?limit=10&offset=${(page - 1) * 10}`
      )
      .pipe(
        map((myhire) => {
          // console.log('pharmacist Data--------->', JSON.stringify(myhire));
          return JSON.stringify(myhire);
        }),
        catchError(this.errorHandler)
      );
  }

  Myhirepharmacistbypharmacyrefid(pharmcyrefid, page) {
    return this.http
      .get<any>(
        AppSettings.apiurls.LISTMYHIREBYPHARMACYUSERREFERENCEID +
        pharmcyrefid +
          `?limit=10&offset=${(page - 1) * 10}`
      )
      .pipe(
        map((myhire) => {
          // console.log('pharmacist Data--------->', JSON.stringify(myhire));
          return JSON.stringify(myhire);
        }),
        catchError(this.errorHandler)
      );
  }

  viewpharmcistDetails(pharmcistrefid) {
    return this.http
      .get<any>(
        AppSettings.apiurls.GETPHARMACISTBYPHAMACISTREFEMECEID +
        pharmcistrefid )
      .pipe(
        map((myhire) => {
          return JSON.stringify(myhire);
        }),
        catchError(this.errorHandler)
      );
  }

  Listproposal(data, page) {
    return this.http
      .post<any>(
        AppSettings.apiurls.LISTPROPOSAL + `limit=10&offset=${(page - 1) * 10}`,
        data
      )
      .pipe(
        map((listproposal) => {
         
          return JSON.stringify(listproposal);
        }),
        catchError(this.errorHandler)
      );
  }

  Updateproposal(updatedata, proposalid) {
    return this.http
      .put<any>(AppSettings.apiurls.UPDATEPROPOSAL + proposalid, updatedata)
      .pipe(
        map((updateproposal) => {
        return JSON.stringify(updateproposal);
        }),
        catchError(this.errorHandler)
      );
  }

  Viewproposal(proposalid) {
    return this.http
      .get<any>(AppSettings.apiurls.UPDATEPROPOSAL + proposalid)
      .pipe(
        map((viewproposal) => {
          // console.log(
          //   'Update proposal data--------->',
          //   JSON.stringify(viewproposal)
          // );
          return JSON.stringify(viewproposal);
        }),
        catchError(this.errorHandler)
      );
  }

  Createcontract(data) {
    return this.http
      .post<any>(AppSettings.apiurls.CREATECONTRACT, { ...data })
      .pipe(
        map((contrat) => {
          // console.log('Pharmacy User Data--------->', JSON.stringify(contrat));
          return JSON.stringify(contrat);
        }),
        catchError(this.errorHandler)
      );
  }

  Updatecontract(updatedata, contractrefid) {
    return this.http
      .put<any>(AppSettings.apiurls.UPDATECONTRACTUSERREFID + contractrefid, updatedata)
      .pipe(
        map((resp) => {
          return JSON.stringify(resp);
        }),
        catchError(this.errorHandler)
      );
  }

  Calculatecontractcharges(contractrefid) {
    return this.http
      .get<any>(
        AppSettings.apiurls.CALCULATECONTRACTCHARGES +
        contractrefid )
      .pipe(
        map((res) => {
          // console.log(
          //   'pharmacist Data--------->',
          //   JSON.stringify(res)
          // );
          return JSON.stringify(res);
        }),
        catchError(this.errorHandler)
      );
  }

  Createpaymenttransation(data) {
    return this.http
      .post<any>(AppSettings.apiurls.CREATEPAYMENTTRANSATION, { ...data })
      .pipe(
        map((response) => {
        
          return response;
        }),
        catchError(this.errorHandler)
      );
  }
  
  SendFirstMessage(data) {
    return this.http
      .post<any>(AppSettings.apiurls.SENDCHATMESSAGE, { ...data })
      .pipe(
        map((createdJoobpost) => {        
          return JSON.stringify(createdJoobpost);
        }),
        catchError(this.errorHandler)
      );
  }

  errorHandler(error: any) {
    console.log('Error--->', error);
    return throwError(error);
  }

  getMembershipDetailsById(id) {
    return this.http
      .get<any>(
        AppSettings.apiurls.GETMEMBERSHIPDETAILSBYID +
        id )
      .pipe(
        map((myhire) => {
          return JSON.stringify(myhire);
        }),
        catchError(this.errorHandler)
      );
  }
}
