import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../../app.settings';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError, from, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  errorHandler(error: any) {
    return throwError(error);
  }

  Createcustomer(customerdetails) {
    return this.http
      .post<any>(AppSettings.apiurls.CREATECUSTOMER, customerdetails)
      .pipe(
        map((customerinfo) => {
          return JSON.stringify(customerinfo);
        }),
        catchError(this.errorHandler)
      );
  }

  PaymentMethodCC(customerdetails) {
    return this.http
      .post<any>(AppSettings.apiurls.CCPAYMENTATTACHED, customerdetails)
      .pipe(
        map((paymentMethodinfo) => {
          return JSON.stringify(paymentMethodinfo);
        }),
        catchError(this.errorHandler)
      );
  }

  PaymentMethodACH(customerdetails) {
    return this.http
      .post<any>(AppSettings.apiurls.ACHPAYMENTMETHOD, customerdetails)
      .pipe(
        map((paymentMethodinfo) => {
          return JSON.stringify(paymentMethodinfo);
        }),
        catchError(this.errorHandler)
      );
  }

  GetCustomer() {
    return this.http
      .get<any>(
        AppSettings.apiurls.GETCUSTOMER
      )
      .pipe(
        map((paymentCustomerInfo ) => {

          return JSON.stringify(paymentCustomerInfo);
        }),
        catchError(this.errorHandler)
      );
  }

  GetPaymentModes(){
     //console.log(" In Get Payment modes")

     return this.http
      .get<any>(
        AppSettings.apiurls.PAYMENTMODES
      )
      .pipe(
        map((customerPaymentModes ) => {
          
          //console.log('---> Data from API', customerPaymentModes);
          return JSON.stringify(customerPaymentModes);
        }),
        catchError(this.errorHandler)
      );

  }

  

  MakePayment(paymentdetails){

    return this.http
    .post<any>(AppSettings.apiurls.PAY, paymentdetails)
    .pipe(
      map((customerPayment) => {
        return JSON.stringify(customerPayment);
      }),
      catchError(this.errorHandler)
    );

  }


  updatepayment(data, referenceid) {
    return this.http
      .put<any>(AppSettings.apiurls.UPDATEPAYMENT + referenceid, data)
      .pipe(
        map((res) => {
        
          return JSON.stringify(res);
        }),
        catchError(this.errorHandler)
      );
    }

    updatatedefault(data, referenceid) {
      return this.http
        .put<any>(AppSettings.apiurls.DEFAULTKEY + referenceid, data)
        .pipe(
          map((res) => {
          
            return JSON.stringify(res);
          }),
          catchError(this.errorHandler)
        );
      }

    // updatatedefault(data, referenceid) {
    //   return this.http
    //     .post<any>(AppSettings.apiurls.CCPAYMENTATTACHED, data)
    //     .pipe(
    //       map((res) => {
          
    //         return JSON.stringify(res);
    //       }),
    //       catchError(this.errorHandler)
    //     );
    //   }
}
