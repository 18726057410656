<div class="content-wrapper">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-2">
          <h1 class="m-0 text-dark">Activity Logs</h1>
        </div>
        <div class="col-10">

        </div>
      </div>
    </div>
  </div>
  <section class="content">
    <div class="col-12" style="width: 100%;">
      <div class="card" *ngFor="let log of logs">

        <div class="card-body">
          <div class="row ">
            <div class="col-1">
              <div>
                <p><b>{{log.id}}</b></p>
              </div>
            </div>
            <div class="col-2">
              <div>
                <p>Full Name<br /><b class="postingname">{{ log.firstname }} {{ log.lastname }}</b></p>
              </div>
            </div>
            <div class="col-2">
              <div>
                <p>Created At<br>{{ log.createdAt | date: medium }}</p>
              </div>
            </div>
            <div class="col-2">
              <div>
                <p>Activity<br />{{ log.activity }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pb-3" *ngIf=' Object.keys(logs).length !== 0'>
        <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" (pageChange)="reloadData()" [pageSize]="10"
          [maxSize]="4" [boundaryLinks]="true"></ngb-pagination>
      </div>
      <div class="card p-3" style="width: 100%; height: 550px;" *ngIf=' Object.keys(logs).length === 0'>
        <div class="card-body">
          <div class=" text-center">
            <!-- <p class="text-center" style="font-size: 25px;">
                          No Records
                        </p> -->
            <img src="../../../assets/images/nodata.jpg" height="350px" />
            <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories - www.freepik.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>