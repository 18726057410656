import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { from } from 'rxjs';
// import { HomeComponent } from './pages/home/home.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ContractsComponent } from './modules/contracts/contracts/contracts.component';
// import { AddsubuserComponent } from './modules/addsubuser/addsubuser.component';
import { HireComponent } from './modules/hire/hire.component';
import { WalletComponent } from './modules/wallet/wallet.component'
import { AuthLayoutComponent } from './modules/shared/layout/auth-layout/auth-layout.component';
import { SuperadmindashboadComponent } from './modules/SuperAdmin/superadmindashboad/superadmindashboad.component'
import { SuperadminAuthLayoutComponent } from './modules/shared/layout/superadmin-auth-layout/superadmin-auth-layout.component';
import { PharmacistComponent } from './modules/SuperAdmin/pharmacist/pharmacist.component'
import { VoucherComponent } from './modules/SuperAdmin/voucher/voucher.component';
import { UserComponent } from './modules/SuperAdmin/user/user.component';
import { MembershipComponent } from './modules/SuperAdmin/membership/membership.component';
import { ViewdetailsComponent } from './modules/SuperAdmin/pharmacist/viewdetails/viewdetails.component';
import { ChatComponent } from './modules/chat/chat.component';
import { ActivityLogComponent } from './modules/SuperAdmin/log/activity-log.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import(`./modules/auth/auth.module`).then((m) => m.AuthModule),
  },
  {
    path: 'pharmacy',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'jobpost',
        loadChildren: () =>
          import(`./modules/jobpost/jobpost.module`).then(
            (m) => m.JobpostModule
          ),
      },
      { path: 'contracts', component: ContractsComponent },
      // { path: 'user', component: UserComponent },
      // { path: 'user/adduser', component: AddsubuserComponent },
      {
        path: 'user',
        loadChildren: () =>
          import(`./modules/user/users.module`).then(
            (m) => m.UsersModule
          ),
      },
      { path: 'hire', component: HireComponent },
      { path: 'chat', component: ChatComponent },
      { path: 'wallet', component: WalletComponent },
      {
        path: 'settings',
        loadChildren: () =>
          import(`./modules/settings/settings.module`).then(
            (m) => m.SettingsModule
          ),
      },

    ],
  },

  {
    path: 'superadmin',
    component: SuperadminAuthLayoutComponent,
    children: [
      {
        path: 'dashboard',
        component: SuperadmindashboadComponent,
      },
      {
        path: 'activity-logs',
        component: ActivityLogComponent
      },
      {
        path: 'pharmacy',
        loadChildren: () =>
          import(`./modules/SuperAdmin/pharmacy/pharmacy.module`).then(
            (m) => m.PharmacyModule
          ),
      },
      {
        path: 'pharmacist',
        component: PharmacistComponent
      },
      {
        path: 'pharmacist/viewdetail',
        component: ViewdetailsComponent
      },
      {
        path: 'voucher',
        component: VoucherComponent
      },
      {
        path: '',
        loadChildren: () =>
          import(`./modules/SuperAdmin/credentialRequest/credential-request.module`).then(
            (m) => m.CredentialRequestModule
          ),
      },
      {
        path: 'user',
        component: UserComponent
      },
      {
        path: 'membership',
        component: MembershipComponent
      }      
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
