import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../../app.settings';
import { Observable, throwError, from, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Headers, RequestOptions, Request, RequestMethod } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class PharmacistService {

  constructor(private http: HttpClient) { }

   public getpharmacistlist(filter,value,page): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.SUPERADMINPHARMACISTLIST +
        `?filter=${filter}&filterValue=${value}&limit=10&offset=${(page - 1) * 10}`
        )
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(this.errorHandler)
      );
   }
   updatePharmacist(pharmacistData, pharmacistId) {
    return this.http
      .put<any>(AppSettings.apiurls.UPDATEPHARMACIST + pharmacistId, pharmacistData)
      .pipe(
        map((updatePharmacy) => {
          console.log(
            'Pharmacy User Data--------->',
            JSON.stringify(updatePharmacy)
          );
          return JSON.stringify(updatePharmacy);
        }),
        catchError(this.errorHandler)
      );
  }

  public getpharmacistdetaillist(pharmacistRefrenceId): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.VIEWPHARMACISTLIST + pharmacistRefrenceId + '/credentials'
      )
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(this.errorHandler)
      );
   }

    errorHandler(error: any) {
    console.log('Error--->', error);
    return throwError(error);
  }
}
