import { Component, OnInit } from '@angular/core';
import { MembershipService } from '../membership/membership.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ToastrService } from '../../../toastr.service';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {
  formVar: FormGroup;
  public errorMessage: any;
  submitted = false;
  public modalShow = false;
  public membershiplist = []
  constructor(
    private membership: MembershipService,
    private router: Router,
    private tosterservice: ToastrService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.formVar = this.fb.group({
      name: ['', Validators.required],
      // state: ['', Validators.required],
      // county: ['', Validators.required],
      monthlyCost: ['', Validators.required],
      contractLength: ['', Validators.required],
      perHireCost: ['', Validators.required],
      companyFees: ['', Validators.required],

    });
    let role = JSON.parse(localStorage.getItem('currentUser')).data?.role
    if (role === 1) {
      this.getmembershipdetail()
    }
    else if (role === 5) {
      this.getmembershipdetail()
    }
    else {
      this.router.navigate(['/login']);

    }
  }
  get f() {
    return this.formVar.controls;
  }
  modalHideShow() {
    this.formVar.reset();
    this.submitted = false;
    this.modalShow = !this.modalShow;

  }
  modalHideShoww() {
    this.modalShow = !this.modalShow;

  }

  getmembershipdetail(): void {
    this.spinner.show()
    this.membership
      .GetMembershipList()
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data) {
            this.errorMessage = '';
            console.log(data);
            this.membershiplist = data?.data
            this.spinner.hide()
          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }

  onlyNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;

    }

    return true;
  }
  onlyText(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) && (charCode >= 96 || charCode <= 105)) {
      //console.log(charCode)
      return false;
    }
    return true;
  }

  onSubmit() {
    this.submitted = true;
    if (this.formVar.invalid) {
      return;
    }
    this.membership.Createmembership(this.formVar.value).subscribe(
      (dataa) => {
        if (dataa) {
          this.errorMessage = '';
          this.tosterservice.Success('Your Membership Created Successfully')
          this.getmembershipdetail()
          this.modalShow = false;
        } else {
          this.errorMessage = 'Unable to Create membership';
          console.log('Unable to Create membership');
        }
      },
      (error) => {
        console.log('Error Message: ', error.error);
        this.errorMessage = error.error;
      }
    );

  }
}
