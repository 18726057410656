<div class="content-wrapper" >
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-6">
                    <h1 class="m-0 text-dark">Dashboard</h1>
                </div>
            </div>
        </div>
    </div>
    <section>
        <div class=" col-xs-12 col-sm-12 " style="height: 530px;">
            <div class="row" *ngFor='let item of Count'>
                <div class="col-sm-4 col-xs-12">
                    <div class="small-box " style="background-color: #FF63EF;padding-top: 20px;    width: 89%;
                    ">
                        <div class="inner pharmacyinner pl-5 text-white">
                            <h3>{{item.PharmacyCount}}</h3>
                
                            <p>Total Pharmacies</p>
                        </div>
                        <div class="icon">
                        </div>

                    </div>
                </div>
                <div class="col-sm-4 col-xs-12">
                    <div class="small-box " style="background-color: #63D0FF;padding-top: 20px;    width: 89%;
                    ">
                        <div class="inner pharmacistinner text-white pl-5">
                            <h3>{{item.PharmacistCount}}</h3>
                
                            <p>Total Pharmacist</p>
                        </div>
                        <div class="icon">
                            <i class="ion ion-bag"></i>
                        </div>
                        <!-- <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
                    </div>
                </div>
                <div class="col-sm-4 col-xs-12">
                    <div class="small-box" style="background-color:#FFDD63;padding-top: 20px;    width: 89%;
                    ">
                        <div class="inner jobinner text-white pl-5">
                            <h3>{{item.JobCount}}</h3>
                
                            <p>Total Jobs</p>
                        </div>
                        <div class="icon">
                            <i class="ion ion-bag"></i>
                        </div>
                        <!-- <a href="#" class="small-box-footer">More info <i class="fas fa-arrow-circle-right"></i></a> -->
                    </div>
                </div>
            </div>
            <div class="card p-3" style="width: 100%;
            height: 550px;" *ngIf='Count.length === 0'>
    <div class="card-body">
        <div class=" text-center" >
          <!-- <p class="text-center" style="font-size: 25px;">
            No Records
          </p> -->
          <img src="../../../assets/images/nodata.jpg" height="350px"/>
          <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories - www.freepik.com</a>
          </div>
        </div>
      </div>
</div>
        </div>
    </section>
</div>


















