import { Component, OnInit } from '@angular/core';
import { PharmacistService } from '../pharmacist.service';
import { first } from 'rxjs/operators';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { ToastrService } from '../../../../toastr.service'
import { NgxSpinnerService } from "ngx-spinner";
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-viewdetails',
  templateUrl: './viewdetails.component.html',
  styleUrls: ['./viewdetails.component.css']
})
export class ViewdetailsComponent implements OnInit {
  public errorMessage: any;
  public pharmacistuserreferenceid: any;
  public pharmacyuserId: any
  public name: any;
  public email: any;
  public phone: any
  public profileImageUrl: any;
  public cityPreference: any;
  public pharmacist: any;
  public pharmacistlist = []
  public s3_arn: any;
  public credentials: [];

  constructor(private Pharmacist: PharmacistService,
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private tosterservice: ToastrService,
    private spinner: NgxSpinnerService) {
    this.s3_arn = AppSettings.apiurls.S3_ARN;
  }

  ngOnInit(): void {
    var sub = this.route.queryParams.subscribe((params) => {
      console.log(params)
      this.pharmacistuserreferenceid = atob(params.id);
      // this.pharmacyuserId = JSON.parse(atob(params.pharmacyuserId));
      // this.name = atob(params.name)
      // this.email = atob(params.email)
      // this.phone = atob(params.phone)
      // this.profileImageUrl = atob(params.profileImageUrl)
    });
    this.getviewpharmacistlist(this.pharmacistuserreferenceid)
  }
  getviewpharmacistlist(pharmacistuserreferenceid): void {
    console.log('Inside getjoblisting');
    this.Pharmacist
      .getpharmacistdetaillist(pharmacistuserreferenceid)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data) {
            var dataaa = data;

            if (dataaa?.data[0].credentials.length > 0) {
              this.credentials = dataaa?.data[0].credentials;
            }

            this.pharmacistlist = dataaa?.data;
            this.pharmacist = dataaa?.data[0];
            this.errorMessage = '';
          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }

}
