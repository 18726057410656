import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { WalletService } from '../wallet/wallet.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { AuthService } from '../auth/auth.service';
import { PaymentService } from '../settings/payment/payment.service';
import { ToastrService } from '../../toastr.service';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {

  stripe;
  formVar: FormGroup;
  formVarr: FormGroup;
  submitted = false;
  submittedd = false;
  public userreferencid: any
  public authenticationId: any;
  public stripeKey: string;
  page = 1
  public modalShoww = false;
  public modalShow = false;
  public errorMessage: any;
  public walletlist = []
  public collectionsize: any;
  public paymentModesList = [];
  public paymentmethodid: any;
  public customerid: any;
  public walletpoint: any;
  public subuserrefid: any;
  constructor(
    private wallet: WalletService,
    private router: Router,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private stripeService: AngularStripeService,
    private authService: AuthService,
    private paymentService: PaymentService,
    private tosterservice: ToastrService,

    config: NgbPaginationConfig
  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
  }
  ngOnInit(): void {
    this.authenticationId = localStorage.getItem('signupUserReferenceId');
    this.userreferencid = JSON.parse(localStorage.getItem('pharmacyUserId'))?.data
    if (this.subuserrefid = localStorage.getItem('subuserpharmacyuserid')) {
      this.getwalletlist(this.subuserrefid, this.page)

    }
    this.getwalletpoint(this.authenticationId)
    if (this.userreferencid) {
      this.getwalletlist(this.userreferencid.referenceId, this.page)

    }

    this.formVar = this.fb.group({
      amount: ['', [Validators.required]],
    });
    this.formVarr = this.fb.group({
      code: ['', [Validators.required]],
    });
    this.GetStripeKey();
    this.paymentModes();
  }
  get f() {
    return this.formVar.controls;
  }
  get ff() {
    return this.formVarr.controls;
  }


  GetStripeKey() {
    this.authService
      .GetStripeKey()
      .pipe(first())
      .subscribe(
        (data) => {
          if (data) {
            this.errorMessage = '';
            this.stripeKey = data.public_key;
            this.initStripe();
          }
        },
        (error) => {
          this.errorMessage = error.text;
        }
      );
  }

  initStripe() {
    this.stripeService.setPublishableKey(this.stripeKey).then((stripe) => {
      this.stripe = stripe;
    });
  }
  BuyPoint() {
    this.formVar.reset();
    this.submitted = false;
    this.modalShoww = true;

  }
  modalHideeShow() {

    this.modalShoww = false;

  }
  reloadDataa() {
    this.getwalletlist(this.userreferencid.referenceId, this.page)
  }
  RedeemVoucher() {
    this.formVar.reset();
    this.submitted = false;
    this.modalShow = true;
  }
  modalHideShoww() {
    this.modalShow = false;

  }

  getwalletlist(userreferencid, page): void {
    this.spinner.show();

    this.wallet
      .getlistwallet(userreferencid, page)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data) {
            this.walletlist = data.data.listResult;
            this.collectionsize = data.data.pagination.totalCount
            var tt = data.data.listResult
            this.spinner.hide();

          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }

  getwalletpoint(userreferencid): void {
    this.spinner.show();
    this.wallet
      .getwalletpoint(userreferencid)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data) {
            this.spinner.hide();

            this.walletpoint = data.data != null ? data.data.walletPoints : 0;

          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }


  // handleStripeJsResult(result) {
  // }

  paymentModes() {
    this.paymentService
      .GetPaymentModes()
      .pipe(first())
      .subscribe(
        (resp) => {
          let paymentModes = JSON.parse(resp)
          this.paymentModesList = paymentModes
          for (var i = 0; i < paymentModes.length; i++) {
            if (paymentModes[i].isdefault === 1) {
              this.paymentmethodid = paymentModes[i].customer_info.id
              this.customerid = paymentModes[i].customer_info.customer
            }
          }
        },
        (error) => { }
      )

  }

  handleServerResponse(response) {

    console.log("Wallet add")
    console.log(response);

    if (response.error) {
      // Show error from server on payment form
      console.log('Error in payment processing')
    } else if (response.requires_action) {
      // Use Stripe.js to handle required card action
      this.stripe.handleCardAction(
        response.payment_intent_client_secret
      ).then((result) => {
        if (result.error) {
          // Show error in payment form,
        } else {
          // The card action has been handled
          this.paymentService.MakePayment({ payment_intent_id: result.paymentIntent.id, idempotencyKey: response.idempotencyKey })
            .pipe(first())
            .subscribe(
              (resp) => {
                console.log(resp);
                // HANDLE RESPONSE HERE
                // SHOW MESSAGE TO USER
              },
              (error) => {
                console.log(error)
              }
            )
        }
      });
    } else {
      // Show success message
      console.log('Success in payment processing')
    }
  }

  handleStripeJsResult(result) {
  }

  onSubmit() {
    this.submitted = true;
    if (this.formVar.invalid) {
      return;
    }
    // console.log(this.paymentmethodid)
    // console.log(this.customerid)
    if (this.paymentmethodid === undefined) {
      // alert('please create a card details')
      Swal.fire({
        title: 'Please Select One Payment Details In Profile Setting',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }
    else if (this.customerid === undefined) {
      Swal.fire({
        title: 'Please Enter Your Card Details In Profile Setting Page',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }
    else {
      let paymentdetails = {
        pharmacyUserId: this.userreferencid.referenceId,
        transactionType: 'buyPoints',
        description: '{}',
        payment_method_id: this.paymentmethodid,
        amount: this.formVar.value.amount * 100,
        currency: 'USD',
        customer: this.customerid,
        walletAmount: this.userreferencid.walletAmount ? this.userreferencid.walletAmount + this.formVar.value.amount * 100 : this.formVar.value.amount * 100,
      }
      Swal.fire({
        title: 'Are you sure?',
        text: "You Want to Proceed With This Payment!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, proced it!'
      }).then((result) => {
        if (result.isConfirmed) {
          console.log('paymentdetails---------------->', paymentdetails);
          this.paymentService.MakePayment(paymentdetails)
            .pipe(first())
            .subscribe(
              (resp) => {
                this.modalShoww = false;
                this.handleServerResponse(JSON.parse(resp))
                Swal.fire(
                  'Payment Successful',
                  'Your payment details has been successfully received.',
                  'success'
                )
                this.getwalletlist(this.userreferencid.referenceId, this.page)
                this.getwalletpoint(this.authenticationId)

              },
              (error) => {
                console.log("error: " + error)
              }
            )
        }
      })
    }
  }


  onSubmitt() {
    this.submittedd = true;
    if (this.formVarr.invalid) {
      return;
    }
    console.log(this.formVarr.value.code)
    var request = {
      code: this.formVarr.value.code,
      pharmacyUserId: this.userreferencid.referenceId
    };

    this.wallet.Createredeemevoucher(request).subscribe(
      (data) => {
        if (data) {
          this.errorMessage = '';
          this.modalShow = false;
          this.tosterservice.Success('Your Voucher Redeemrd Successfully')

          this.getwalletlist(this.userreferencid.referenceId, this.page)
          this.getwalletpoint(this.authenticationId)


        } else {
          this.errorMessage = 'Unable to Save Redeem Voucher';
        }
      },
      (error) => {
        console.log('Error Message: ', error.error);
        this.errorMessage = error.error;
        this.tosterservice.Error('The Code Does Not Exists. It Must Be Either Redeemed Or Expired')

      }
    );
  }
}
