import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../app.settings';
import { Observable, throwError, from, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Headers, RequestOptions, Request, RequestMethod } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class HireService {

  constructor(private http: HttpClient) {}

  public getlisthire(authonticationid, page): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.LISTHIRE +
          authonticationid +
          `?limit=9&offset=${(page - 1) * 10}`
      )
      .pipe(
        map((res) => {
          return res;
        }),
        catchError(this.errorHandler)
      );
  }





  
  writereview(data) {
    console.log('CreatePharmacyUser before api call auth.service');
    return this.http
      .post<any>(AppSettings.apiurls.WRITEREVIEW, { ...data })
      .pipe(
        map((review) => {
          console.log('Pharmacy User Data--------->', JSON.stringify(review));
          return JSON.stringify(review);
        }),
        catchError(this.errorHandler)
      );
  }


  errorHandler(error: any) {
    console.log('Error--->', error);
    return throwError(error);
  }


}
