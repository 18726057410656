import { Component, OnInit } from '@angular/core';
import { UserService } from '../user/user.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import {Superadminsection} from '../../../shared/enum/constants'
import {ToastrService} from '../../../toastr.service'

import {
  FormBuilder,
  FormControl,
    ValidatorFn,
  FormGroup,
  FormArray,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  formVar: FormGroup;
  editformVar:FormGroup;
  page=1;
public referenceid :any
public errorMessage :any;
public sabuserlist=[]
public collectionsize :any;
modalShow=false
editmodalShow=false
public submitted = false
public sectionconst :any;
public edit ='false';
public sectionvalue =[];
public subuserrefid :any;
public subuser :any;
  constructor(
    private user: UserService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private tosterservice:ToastrService,

    config: NgbPaginationConfig
  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
    this.sectionconst = Superadminsection;

    // this.subuser = JSON.parse(localStorage.getItem('subuseritem'))

    this.formVar = this.fb.group({
      firstname: ['',[Validators.required]],
       lastname: ['', Validators.required],
       phone: ['', [Validators.required,Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
 ]],
        email: ['', [Validators.required, Validators.pattern(/^([a-zA-Z]{1,})+([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/)]],
        myCategory: this.fb.array(
          this.sectionconst.map(
            (item) => {
              return this.getstatus(item.key)
            }
          ),
        ),
    });

    this.editformVar = this.fb.group({
      firstname: ['',[Validators.required]],
       lastname: ['', Validators.required],
       phone: ['', [Validators.required,Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
 ]],
       email: ['', [Validators.required]],
       myCategory: this.fb.array(
         this.sectionconst.map(
           (item) => {
              return this.getstatus(item.key)
           }
         ),
       ),
    });
    this.editformVar.get('firstname').disable();
    this.editformVar.get('lastname').disable();
this.editformVar.get('email').disable();
this.editformVar.get('phone').disable();
  }

  getstatus(a){
    if(this.subuser){
      let x = this.subuser.Section.filter(item => item.key === a && item.value ===true )   
      return x.length > 0
    }
      }

  ngOnInit(): void {
   

    let role = JSON.parse(localStorage.getItem('currentUser')).data?.role
    if(role===1 ){
      this.getsubuserlist(this.page)
    }
   else if(role===5 ){
      this.getsubuserlist(this.page)
    }
    else{
      this.router.navigate(['/login']);

    }
  }
 

  adnewuser() {
    this.submitted = false;
    this.formVar.reset();
    this.modalShow = true;

  }
  editmodalHide() {
        this.editmodalShow=false
localStorage.removeItem('subuseritem')
  }

  modalHide() {
    this.modalShow=false
}

  edituser(userReferenceID,item){
    this.subuserrefid = userReferenceID;
    this.subuser = item
    this.edit = 'true'
    this.editmodalShow = true;
    localStorage.setItem('subuseritem',JSON.stringify(item))
    this.subuser = JSON.parse(localStorage.getItem('subuseritem'))
    this.editformVar = this.fb.group({
      firstname: ['',[Validators.required]],
       lastname: ['', Validators.required],
       phone: ['', [Validators.required,Validators.pattern(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
 ]],
       email: ['', [Validators.required]],
       myCategory: this.fb.array(
         this.sectionconst.map(
           (item) => {
              return this.getstatus(item.key)
           }
         ),
       ),
    });
    this.editformVar.patchValue({
      firstname: this.subuser?.firstname ,
      lastname: this.subuser?.lastname,
      email: this.subuser?.email,
      phone: this.subuser?.phone,
    });
  
}

get f() {
  return this.formVar.controls;
} 

  reloadDataa() {
    this.getsubuserlist(this.page)
  }
  getsubuserlist(page): void {
                   this.spinner.show();

    this.user
      .Getsabuselist(page)
      .pipe(first())
      .subscribe(
        (data) => {
          console.log(data);
          if (data) {
            this.errorMessage = '';
            this.sabuserlist = data.data.listResult;
            this.collectionsize = data.data.pagination.totalCount
                   this.spinner.hide();

            console.log('Job Listing', this.sabuserlist);

          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }

  Delete(userReferenceID){
    var referenceid = userReferenceID
      var requestt = {
        isDeleted:1  
       };
      //console.log(request);
      Swal.fire({
        title: 'Are you sure?',
        text: "You want to delete this user!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            'Deleted!',
            'Your user has been deleted.',
            'success'
          )
          this.user.Updatepharmacysubuser(requestt, referenceid).subscribe(
            (data) => {
              if (data) {
                this.getsubuserlist(this.page)
              } else {
                this.errorMessage = 'Unable to Save user';
                console.log('Unable to Save user');
              }
            },
            (error) => {
              console.log('Error Message: ', error.error);
              this.errorMessage = error.error;
            }
          );
        }
      })
    
  }



  onlyNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;

    }
      
    return true;
  }
  onlyText(event): boolean {
     const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57 )&& (charCode >= 96 || charCode <= 105)) {
      //console.log(charCode)
      return false;

    }
        return true;

  }
 

  onSubmit() {
    this.sectionvalue=this.sectionconst.map((item,i)=>{
      let obj = {key:item.key,value:this.editformVar.value.myCategory[i]}
      return obj
    })
      var requestt = {
          section: this.sectionvalue
      };
      //console.log(request);
      this.user.Updatepharmacysubuser(requestt, this.subuserrefid).subscribe(
        (data) => {
          if (data) {
            this.getsubuserlist(this.page)
            this.editmodalShow=false
            this.tosterservice.Success('SubUser Update  Successfully ')
                localStorage.removeItem('subuseritem')


          } else {
            this.errorMessage = 'Unable to Save Subuser';
            console.log('Unable to Save Subuser');
          }
        },
        (error) => {
          console.log('Error Message: ', error.error);
          this.errorMessage = error.error;
        }
      );
  }



  submit(){
    this.sectionvalue=this.sectionconst.map((item,i)=>{
      let obj = {key:item.key,value:this.formVar.value.myCategory[i]}
      return obj
    })
      this.submitted = true;
      if (this.formVar.invalid) {
        return;
      }
         var request = {
        role:5,
        pharmacyuserId:null,
        firstname: this.formVar.value.firstname,    
        lastname: this.formVar.value.lastname,
        email: this.formVar.value.email,
        phone: this.formVar.value.phone,
        superadminUser: 1,
  
        // section: this.sectionvalue
            section: this.sectionvalue
      };
      this.user.Createsubuser(request).subscribe(
        (data) => {
          if (data) {
            this.errorMessage = '';
            this.modalShow=false
            this.getsubuserlist(this.page)
        
            this.tosterservice.Success('Superadmin SubUser Create  Successfully ,Check Subusers Email To Create a Password ')
  
  
          } else {
            this.errorMessage = 'Unable to Save review';
            console.log('Unable to Save review');
          }
        },
        (error) => {
          console.log('Error Message: ', error);
          this.errorMessage = error;
        }
      );
    
  }

}

