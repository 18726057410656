<nav class="main-header navbar navbar-expand navbar-white navbar-light">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" data-widget="pushmenu" href="#" role="button"
        ><i class="fa fa-bars"></i
      ></a>
    </li>
    <!-- <li class="nav-item d-none d-sm-inline-block">
      <a href="index3.html" class="nav-link">Home</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a href="#" class="nav-link">Contact</a>
    </li> -->
  </ul>

  <!-- SEARCH FORM -->
  <!-- <form class="form-inline ml-3">
    <div class="input-group input-group-sm">
      <input
        class="form-control form-control-navbar"
        type="search"
        placeholder="Search"
        aria-label="Search"
      />
      <div class="input-group-append">
        <button class="btn btn-navbar" type="submit">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </form> -->

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <!-- Messages Dropdown Menu -->
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <!-- <i class="far fa-comments"></i>
        <span class="badge badge-danger navbar-badge">3</span> -->
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <a href="#" class="dropdown-item">
          <!-- Message Start -->

          <!-- Message End -->
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <!-- Message Start -->
          <div class="media">
            <img
              src="dist/img/user8-128x128.jpg"
              alt="User Avatar"
              class="img-size-50 img-circle mr-3"
            />
            <div class="media-body">
              <h3 class="dropdown-item-title">
                John Pierce
                <span class="float-right text-sm text-muted"
                  ><i class="fas fa-star"></i
                ></span>
              </h3>
              <p class="text-sm">I got your message bro</p>
              <p class="text-sm text-muted">
                <i class="far fa-clock mr-1"></i> 4 Hours Ago
              </p>
            </div>
          </div>
          <!-- Message End -->
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <!-- Message Start -->
          <div class="media">
            <img
              src="dist/img/user3-128x128.jpg"
              alt="User Avatar"
              class="img-size-50 img-circle mr-3"
            />
            <div class="media-body">
              <h3 class="dropdown-item-title">
                Nora Silvester
                <span class="float-right text-sm text-warning"
                  ><i class="fas fa-star"></i
                ></span>
              </h3>
              <p class="text-sm">The subject goes here</p>
              <p class="text-sm text-muted">
                <i class="far fa-clock mr-1"></i> 4 Hours Ago
              </p>
            </div>
          </div>
          <!-- Message End -->
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item dropdown-footer">See All Messages</a>
      </div>
    </li>
    <!-- Notifications Dropdown Menu -->
    <li class="nav-item dropdown">
      <a class="nav-link" data-toggle="dropdown" href="#">
        <!-- <i class="far fa-bell"></i> -->
        <!-- <span class="badge badge-warning navbar-badge">15</span> -->
      </a>
      <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
        <span class="dropdown-item dropdown-header">15 Notifications</span>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-envelope mr-2"></i> 4 new messages
          <span class="float-right text-muted text-sm">3 mins</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-users mr-2"></i> 8 friend requests
          <span class="float-right text-muted text-sm">12 hours</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item">
          <i class="fas fa-file mr-2"></i> 3 new reports
          <span class="float-right text-muted text-sm">2 days</span>
        </a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item dropdown-footer"
          >See All Notifications</a
        >
      </div>
    </li>
    <li class="nav-item">
      <a
        class="nav-link"
        data-widget="control-sidebar"
        data-slide="true"
        href="#"
        role="button"
        >
        <!-- <i class="fas fa-th-large"></i > -->
        </a>
    </li>
  </ul>
</nav>
<aside class="control-sidebar control-sidebar-dark" style="display: block">
  <!-- Control sidebar content goes here -->
  <div class="p-3 control-sidebar-content">
    <h5>Customize AdminLTE</h5>
    <hr class="mb-2" />
    <div class="mb-1">
      <input type="checkbox" value="1" class="mr-1" /><span
        >No Navbar border</span
      >
    </div>
    <div class="mb-1">
      <input type="checkbox" value="1" class="mr-1" /><span
        >Body small text</span
      >
    </div>
    <div class="mb-1">
      <input type="checkbox" value="1" class="mr-1" /><span
        >Navbar small text</span
      >
    </div>
    <div class="mb-1">
      <input type="checkbox" value="1" class="mr-1" /><span
        >Sidebar nav small text</span
      >
    </div>
    <div class="mb-1">
      <input type="checkbox" value="1" class="mr-1" /><span
        >Footer small text</span
      >
    </div>
    <div class="mb-1">
      <input type="checkbox" value="1" class="mr-1" /><span
        >Sidebar nav flat style</span
      >
    </div>
    <div class="mb-1">
      <input type="checkbox" value="1" class="mr-1" /><span
        >Sidebar nav legacy style</span
      >
    </div>
    <div class="mb-1">
      <input type="checkbox" value="1" class="mr-1" /><span
        >Sidebar nav compact</span
      >
    </div>
    <div class="mb-1">
      <input type="checkbox" value="1" class="mr-1" /><span
        >Sidebar nav child indent</span
      >
    </div>
    <div class="mb-1">
      <input type="checkbox" value="1" class="mr-1" /><span
        >Main Sidebar disable hover/focus auto expand</span
      >
    </div>
    <div class="mb-4">
      <input type="checkbox" value="1" class="mr-1" /><span
        >Brand small text</span
      >
    </div>
    <h6>Navbar Variants</h6>
    <div class="d-flex">
      <div class="d-flex flex-wrap mb-3">
        <div
          class="bg-primary elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-secondary elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-info elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-success elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-danger elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-indigo elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-purple elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-pink elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-navy elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-lightblue elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-teal elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-cyan elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-dark elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-gray-dark elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-gray elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-light elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-warning elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-white elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
        <div
          class="bg-orange elevation-2"
          style="
            width: 40px;
            height: 20px;
            border-radius: 25px;
            margin-right: 10px;
            margin-bottom: 10px;
            opacity: 0.8;
            cursor: pointer;
          "
        ></div>
      </div>
    </div>
    <h6>Accent Color Variants</h6>
    <div class="d-flex"></div>
    <div class="d-flex flex-wrap mb-3">
      <div
        class="bg-primary elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-warning elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-info elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-danger elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-success elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-indigo elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-lightblue elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-navy elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-purple elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-fuchsia elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-pink elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-maroon elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-orange elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-lime elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-teal elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-olive elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
    </div>
    <h6>Dark Sidebar Variants</h6>
    <div class="d-flex"></div>
    <div class="d-flex flex-wrap mb-3">
      <div
        class="bg-primary elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-warning elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-info elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-danger elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-success elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-indigo elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-lightblue elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-navy elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-purple elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-fuchsia elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-pink elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-maroon elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-orange elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-lime elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-teal elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-olive elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
    </div>
    <h6>Light Sidebar Variants</h6>
    <div class="d-flex"></div>
    <div class="d-flex flex-wrap mb-3">
      <div
        class="bg-primary elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-warning elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-info elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-danger elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-success elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-indigo elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-lightblue elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-navy elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-purple elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-fuchsia elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-pink elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-maroon elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-orange elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-lime elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-teal elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-olive elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
    </div>
    <h6>Brand Logo Variants</h6>
    <div class="d-flex"></div>
    <div class="d-flex flex-wrap mb-3">
      <div
        class="bg-primary elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-secondary elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-info elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-success elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-danger elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-indigo elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-purple elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-pink elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-navy elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-lightblue elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-teal elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-cyan elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-dark elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-gray-dark elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-gray elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-light elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-warning elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-white elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <div
        class="bg-orange elevation-2"
        style="
          width: 40px;
          height: 20px;
          border-radius: 25px;
          margin-right: 10px;
          margin-bottom: 10px;
          opacity: 0.8;
          cursor: pointer;
        "
      ></div>
      <a href="javascript:void(0)">clear</a>
    </div>
  </div>
</aside>
