import { Component, OnInit } from '@angular/core';
import { from } from 'rxjs';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AppSettings } from 'src/app/app.settings';
import { HireService } from '../hire/hire.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../dashboard/dashboard.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-hire',
  templateUrl: './hire.component.html',
  styleUrls: ['./hire.component.css']
})
export class HireComponent implements OnInit {
  public authenticationId: any;
  page = 1
  public errorMessage: any;
  public name: any;
  public finalcost: any;
  public datetime: any;
  public contractrefid: any;
  public contrecttitle: any;
  public collectionsize: any;
  public expectedtime: any;
  public s3_arn: any;
  modalShow = false

  public hielist = []
  constructor(
    private dashboard: DashboardService,
    private hire: HireService,
    private router: Router,
    private fb: FormBuilder,
    config: NgbPaginationConfig,
    private spinner: NgxSpinnerService

  ) {
    config.size = 'sm';
    config.boundaryLinks = true;
    this.s3_arn = AppSettings.apiurls.S3_ARN;
  }
  ngOnInit(): void {
    this.authenticationId = localStorage.getItem('signupUserReferenceId');

    this.gethirelist(this.authenticationId, this.page)
  }
  reloadDataa() {
    this.gethirelist(this.authenticationId, this.page)

  }
  gethirelist(authonticatinid, page): void {
    this.spinner.show();

    this.hire
      .getlisthire(authonticatinid, page)
      .pipe(first())
      .subscribe(
        (data) => {
          if (data) {
            this.hielist = data.data.listResult;
            var tt = data.data.listResult
            this.collectionsize = data.data.pagination.totalCount
            this.spinner.hide();


          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
          this.errorMessage = error.text;
        }
      );
  }
  modalHideShoww(name, datetime, cost, contractid, title, expectedtime) {

    this.modalShow = !this.modalShow;
    this.name = name;
    this.finalcost = cost;
    this.datetime = datetime;
    this.contractrefid = contractid;
    this.contrecttitle = title
    this.expectedtime = expectedtime
  }
  modalHide() {
    this.modalShow = false

  }

  opencard(id, refid) {
    // this.router.navigate(['pharmacy/jobpost/viewproposal'], {
    //     queryParams: { proposalId: btoa(id), proposalreferanseid: btoa(refid) },
    // });
    var proposalidvalue = id
    var proposalid = refid;
    this.router.navigate(['pharmacy/jobpost/viewproposal'], {
      queryParams: { proposalreferanseid: btoa(proposalid), proposalId: btoa(proposalidvalue), hire: true },
    });

  }

  submit() {
    var request = {
      //  title: this.contrecttitle,
      status: "cancelledbypharmacy"
    }
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.dashboard
          .Cancelhire(request, this.contractrefid)
          .subscribe(
            (data) => {
              if (data) {
                this.errorMessage = '';
                var dataa = JSON.parse(data)
                this.gethirelist(this.authenticationId, this.page)
                this.modalShow = false
                this.gethirelist(this.authenticationId, this.page)

              } else {
                this.errorMessage = 'Unable to Save Jobpost';
                console.log('Unable to Save Jobpost');
              }
            },
            (error) => {
              console.log('Error Message: ', error.error);
              this.errorMessage = error.error;
            }

          );
        Swal.fire(
          'Canceled!',
          'Your Hire has been Canceled.',
          'success'
        )
      }
    })

  }


}
