<div class="content-wrapper">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">Pharmacist
          </h1>
        </div>
        <div class="col-sm-6">
        </div>
      </div>
    </div>
  </div>
  <section class="content">
    <div class="card">
      <div class="card-body">
        <div class="col-12">
          <div class="row">
            <div class="col-6"></div>
            <div class="col-2">
              <select class="form-control" #mySelect (change)="onOptionsSelected(mySelect.value)"
                style="    color: blue;                                ">
                <option *ngFor="let item of filterkey" value={{item.value}}>{{item.key}}</option>

              </select>
            </div>
            <div class="col-4">
              <form [formGroup]="formVar">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-search" aria-hidden="true"></i></span>
                  </div>
                  <input type="text" class="form-control" placeholder="Search" formControlName="search"
                    (input)='searchkey($event.target.value) '>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12" style="width: 100%;">
      <div class="card" *ngFor="let item of pharmacistlist;index as i;">

        <div class="card-body">
          <div class="row ">
            <div class="col-1">
              <div>
                <p><b>{{(toNumber(page)-1)*10+toNumber(i)+1}}.</b></p>
              </div>
            </div>
            <div class="col-1">
              <div>
                <div *ngIf="item.profileImageUrl; else noImageTemplate">
                  <img src="{{s3_arn}}{{item.profileImageUrl}}" width="70px "
                    height="70px" style="border-radius: 50%;" />
                </div>

                <ng-template #noImageTemplate>
                  <img src="../../../assets/images/placeholder-image.png" width="70px " height="70px"
                    style="border-radius: 50%;" />
                </ng-template>
              </div>
            </div>
            <div class="col-2">
              <div>
                <p><b class="pharmacistname" (click)='viewdetails(item.pharmacistReferenceId)'>{{ item.Name }}</b><br />
                  {{item.email}}</p>
              </div>
            </div>
            <div class="col-2">
              <div>
                <p>{{ item.phone }}</p>
              </div>
            </div>
            <div class="col-2">
              <div>
                <p>0<br />Active Jobs</p>
              </div>
            </div>

            <div class="col-2 d-flex">
              <div class=" pt-4 pr-5">
                <p *ngIf="item.pharmacistStatus==='active'" style="color: green;"><b>ACTIVE</b></p>
                <p *ngIf="item.pharmacistStatus==='pending'" style="color: red;"><b>PENDING</b></p>

              </div>
            </div>
            <div class="col-2">
              <div class=" d-flex">
                <div class=" d-flex" data-widget="treeview" role="menu" data-accordion="false">
                  <div class=" d-flex">

                    <div class=" d-flex" style="padding-top:23px;" *ngIf='clickedid === item.pharmacistId'>
                      <div class="nav-item">
                        <a class="nav-link">
                          <p (click)="
                        viewdetails(
                          item.pharmacistReferenceId,
                          item.pharmacistId,item.email,item.Name,item.phone
                        )
                      "><i class="fa fa-eye" title="View jobPost" aria-hidden="true"
                              style="color: #00A3FF;cursor: pointer;"></i>
                          </p>
                        </a>
                      </div>
                      <!-- <div class="nav-item">
                                                <a class="nav-link">
                                                    <p (click)="
                        editdetails(item.pharmacistReferenceId)
                      "><i class="fa fa-pencil-square-o" title="Edit Job Post" aria-hidden="true"
                                                            style="color: #8A8A8A;cursor: pointer;"></i>
                                                    </p>
                                                </a>
                                            </div> -->
                      <div class="nav-item">
                        <a class="nav-link">
                          <p (click)="deletedetalis(item.pharmacistReferenceId)"><i class="fa fa-trash"
                              title="Delete Job Post" aria-hidden="true" style="color: #FF2E2E;cursor: pointer;"></i>
                          </p>
                        </a>
                      </div>

                    </div>
                    <a class="nav-link">
                      <i class="nav-icon fa fa-tachometer-alt"></i>

                      <p>

                        <i style="color: #00A3FF;cursor: pointer;" [id]="item.pharmacistId" class="fa fa-ellipsis-h"
                          title="View" *ngIf="
                                                           clickedid !== item.pharmacistId"
                          (click)='openclose(item.pharmacistId)'></i>

                        <i style="cursor: pointer;" title="Close" [id]="item.pharmacistId" class="fa fa-times"
                          *ngIf="clickedid === item.pharmacistId " (click)='closeopen(item.pharmacistId)'></i>
                      </p>
                    </a>
                  </div>



                </div>
              </div>
            </div>



          </div>
        </div>
      </div>

      <div *ngIf=' pharmacistlist.length !== 0' class="pb-5">
        <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" (pageChange)="reloadData()" [pageSize]="10"
          [maxSize]="4" [boundaryLinks]="true"></ngb-pagination>
      </div>
      <div class="card p-3" style="width: 100%;
                                    height: 550px;" *ngIf='pharmacistlist.length === 0'>
        <div class="card-body">
          <div class=" text-center">
            <!-- <p class="text-center" style="font-size: 25px;">
                    No Records
                  </p> -->
            <img src="../../../assets/images/nodata.jpg" height="350px" />
            <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories - www.freepik.com</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
