<div class="content-wrapper">
    <div class="content-header">
        <div class="">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <h1 class="m-0 text-dark">Voucher</h1>
                        </div>
                        <div class="col-6">
                            <button class="btn  float-right" (click)='modalHideShow()'>Create Voucher</button>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
    <section class=" row content">
        <div class="col-4" style="width: 100%;" *ngFor="let item of voucherlist;index as i;">
            <div class="card voucherinfo">
                <div class="col-12">
                    <div class="row">
                        <div class="col-3" style="background: #0052CC;">
                            <p class="text-center amount">${{item.amount}}</p>
                        </div>
                        <div class="col-9">

                            <div class="row py-2">
                                <div class="col-1"></div>
                                <div class="col-2">
                                    <div><img src="../../../../assets/images/Rectangle 1629.png" height="10px;"
                                            width="10px" /></div>
                                    <div><img src="../../../../assets/images/Rectangle 1630.png"
                                            style="padding-left: 4px;height: 35px;" /></div>
                                    <div><img src="../../../../assets/images/Rectangle 1631.png" height="10px;"
                                            width="10px" /></div>

                                </div>
                                <div class="col-9">
                                    <div style="padding-top: 5px;">
                                        <p>{{item.createdDate | date }}</p>
                                    </div>
                                    <div style="padding-top: 15px;">
                                        <p>{{item.expiryDate | date}}</p>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="text-center">
                                        <p class="textshow">Voucher Code
                                        </p>
                                        <p>{{item.voucherCode || "--"}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-5">
                                    <div class="text-center">
                                        <p class="textshow">Total Vouchers
                                        </p>
                                        <p style="font-size: 22px;margin-top:-20px;">{{item.voucherCount}}</p>
                                    </div>
                                </div>
                                <div class="col-7">
                                    <div class="text-center">
                                        <p class="textshow">Number of redemption</p>
                                        <p style="font-size: 22px;margin-top:-20px;" class="text-center">
                                            {{item.vouchersRedeemed}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div *ngIf=' voucherlist.length !== 0' class="pb-3">
        <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" (pageChange)="reloadData()" [pageSize]="9"
            [maxSize]="4" [boundaryLinks]="true"></ngb-pagination>
    </div>
    <div class="card p-3" style="width: 100%;
                height: 550px;" *ngIf='voucherlist.length === 0'>
        <div class="card-body">
            <div class=" text-center">
                <!-- <p class="text-center" style="font-size: 25px;">
                        No Records
                      </p> -->
                <img src="../../../assets/images/nodata.jpg" height="350px" />
                <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories - www.freepik.com</a>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="myModal" [ngClass]="modalShow ? 'success' : 'black'" role="dialog" data-backdrop="false">
    <div class="modal-dialog">
        <div class="modal-content" style="    border-radius: 16px;">
            <!-- Modal Header -->
            <div class="col-12 " style="border-bottom: 1px solid rgb(206, 202, 202);">
                <div class="row pt-2" style="width: 100%;">
                    <div class="col-8">
                        <h4 class="modal-title heading">Create Voucher</h4>
                    </div>
                    <div class="col-4">
                        <i class="fa fa-times float-right " (click)="modalHideShoww()" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="card-body">
                    <form [formGroup]="formVar" class="" (ngSubmit)="onSubmit()">
                        <div class="card-body">
                            <div class="row form-group">
                                <div class="col-6"> <label for="exampleFormControlTextarea1">Voucher Name</label></div>
                                <div class="col-6"> <input type="text" class="form-control"
                                        (keypress)='onlyText($event)' formControlName="voucherName" [ngClass]="{
                                      'is-invalid': submitted && f.voucherName.errors
                                    }" />
                                    <div *ngIf="submitted && f.voucherName.errors" class="invalid-feedback">
                                        <div *ngIf="f.voucherName.errors.required">
                                            Voucher name is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-6"> <label for="exampleFormControlTextarea1">Total no: of
                                        vouchers</label></div>
                                <div class="col-6"> <input type="text" class="form-control"
                                        (keypress)='onlyNumber($event)' formControlName="numberOfVouchers" [ngClass]="{
                                                              'is-invalid': submitted && f.numberOfVouchers.errors
                                                            }" />
                                    <div *ngIf="submitted && f.numberOfVouchers.errors" class="invalid-feedback">
                                        <div *ngIf="f.numberOfVouchers.errors.required">
                                            Total no of vouchers is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-6"> <label for="exampleFormControlTextarea1">Expiry Date</label></div>
                                <div class="col-6"> 
                                    <input type="date" class="form-control" formControlName="expiryDate"
                                        [ngClass]="{'is-invalid': submitted && f.expiryDate.errors}" min="{{ todayDate }}" onkeydown="return false" />
                                    <div *ngIf="submitted && f.expiryDate.errors" class="invalid-feedback">
                                        <div *ngIf="f.expiryDate.errors.required">
                                            Expiry Date is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-6"> <label for="exampleFormControlTextarea1">Total Cost</label></div>
                                <div class="col-6"> <input type="text" class="form-control" formControlName="amount"
                                        (keypress)='onlyNumber($event)' [ngClass]="{
                                                                                      'is-invalid': submitted && f.amount.errors
                                                                                    }" min="{{1}}" />
                                    <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                                        <div *ngIf="f.amount.errors.required">
                                            Total Cost is required
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- /.card-body -->
                        <div class=" text-center ">
                            <button class="btnn">Save</button>
                        </div>
                    </form>
                </div>
                <!-- /.card-body -->
            </div>
        </div>
    </div>
</div>