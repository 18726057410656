import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
@Component({
  selector: 'app-superadmin-sidebar',
  templateUrl: './superadmin-sidebar.component.html',
  styleUrls: ['./superadmin-sidebar.component.css']
})
export class SuperadminSidebarComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router) { }
  activeTab = 'dashboard';
  public show = false;
  public sectionn = []
  ngOnInit(): void {
    if (JSON.parse(localStorage.getItem('subusersection'))) {
      this.sectionn = JSON.parse(localStorage.getItem('subusersection'))[0].section
      console.log(JSON.parse(localStorage.getItem('subusersection'))[0].section)

    }
  }
  logoutt() {
    localStorage.removeItem('pharmacyUser');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('signupUserReferenceId');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('pharmacyUserId');
    localStorage.removeItem('pharmacylist');
    localStorage.removeItem('joblistvalue');
    localStorage.removeItem('subusersection')
    localStorage.removeItem('subuseritem')
    localStorage.removeItem('sidebarstringifyobj')
    localStorage.removeItem('sideBarObject')
    localStorage.removeItem('topprogressbarline')

    this.router.navigate(['/login']);
  }

  viewDashboard() {
    this.activeTab = 'dashboard';
    this.router.navigate(['superadmin/dashboard']);
  }
  viewPharmacy() {
    this.activeTab = 'pharmacy';
    this.router.navigate(['/superadmin/pharmacy']);
  }
  viewPharmacist() {
    this.activeTab = 'pharmacist';
    this.router.navigate(['/superadmin/pharmacist']);

  }
  viewvoucher() {
    this.activeTab = 'voucher';
    this.router.navigate(['/superadmin/voucher']);
  }
  viewuser() {
    this.activeTab = 'user';
    this.router.navigate(['/superadmin/user']);
  }
  viewcredential() {
    this.activeTab = 'credentialRequest';
    this.router.navigate(['/superadmin/credentialrequest']);
  }
  viewmembership() {
    this.activeTab = 'membership';
    this.router.navigate(['/superadmin/membership']);
  }
  viewActivityLogs() {
    this.activeTab = 'activity-logs';
    this.router.navigate(['/superadmin/activity-logs']);
  }
  setting() {
    // this.activeTab = 'Settings';
    this.show = !this.show
  }
  view(key) {
    this.activeTab = key;
    console.log(key)
    this.router.navigate(['/superadmin/' + key.toLowerCase()]);
  }
}
