<style>
    .loader ::ng-deep .overlay {
        position: fixed !important;
        background-color: white;
    }
</style>
<app-header></app-header>
<app-sidebar></app-sidebar>
<div style="position: fixed;
    z-index: 99;">

    <ngx-spinner bdColor = "rgba(0, 0, 0, 0.4)" class="loader" size="medium" color="#fff" type="ball-clip-rotate" [fullScreen]="false">
        <p style="color: white;top: 0;
   "> Loading... </p>
    </ngx-spinner>
</div>
<router-outlet>
      
</router-outlet>

 <!-- <div class="content-wrapper" style="min-height: 0px!important;" > -->
    <app-footer></app-footer>
    
  <!-- </div>  -->
