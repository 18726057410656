import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { AuthLayoutComponent } from './modules/shared/layout/auth-layout/auth-layout.component';
import { UnauthLayoutComponent } from './modules/shared/layout/unauth-layout/unauth-layout.component';
import { JwtInterceptor } from './core/jwt.interceptor';
import { SidebarComponent } from './modules/shared/layout/authorized/sidebar/sidebar.component';
import { HeaderComponent } from './modules/shared/layout/authorized/header/header.component';
// import {SuperadminSidebarComponent} from './modules/shared/layout/superadmin-authorized/sidebar/sidebar.component'
// import {SuperadminHeaderComponent} from './modules/shared/layout/superadmin-authorized/header/header.component'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContractsComponent } from './modules/contracts/contracts/contracts.component';
import {HireComponent} from './modules/hire/hire.component';
import { WalletComponent } from './modules/wallet/wallet.component';
import { NgForm } from '@angular/forms';
import { ToastrService } from './toastr.service'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from "ngx-spinner";
import { from } from 'rxjs';
import { SuperadmindashboadComponent } from './modules/SuperAdmin/superadmindashboad/superadmindashboad.component';
import { SuperadminAuthLayoutComponent } from './modules/shared/layout/superadmin-auth-layout/superadmin-auth-layout.component';
import { SuperadminSidebarComponent } from './modules/shared/layout/superadmin-authorized/superadmin-sidebar/superadmin-sidebar.component';
import { PharmacistComponent } from './modules/SuperAdmin/pharmacist/pharmacist.component';
import { VoucherComponent } from './modules/SuperAdmin/voucher/voucher.component';
import { UserComponent } from './modules/SuperAdmin/user/user.component';
import { SuperadminHeaderComponent } from './modules/shared/layout/superadmin-authorized/superadmin-header/superadmin-header.component';
import { MembershipComponent } from './modules/SuperAdmin/membership/membership.component';
import { ViewdetailsComponent } from './modules/SuperAdmin/pharmacist/viewdetails/viewdetails.component';
import { FooterComponent } from './modules/shared/layout/authorized/footer/footer.component';
import { SuperadminFooterComponent } from './modules/shared/layout/superadmin-authorized/superadmin-footer/superadmin-footer.component';
import { ChatComponent } from './modules/chat/chat.component';
import { ActivityLogComponent } from './modules/SuperAdmin/log/activity-log.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AuthLayoutComponent,
    UnauthLayoutComponent,
    SidebarComponent,
    HeaderComponent,
    // SuperadminSidebarComponent,
    // SuperadminHeaderComponent,
    ContractsComponent,
    // UserComponent,
    // AddsubuserComponent,
    HireComponent,
    // CheckoutachComponent,
    WalletComponent,
    SuperadmindashboadComponent,
    SuperadminAuthLayoutComponent,
    SuperadminSidebarComponent,
    PharmacistComponent,
    VoucherComponent,
    UserComponent,
    SuperadminHeaderComponent,
    MembershipComponent,
    ViewdetailsComponent,
    FooterComponent,
    SuperadminFooterComponent,
    ChatComponent,
    ActivityLogComponent
  ],
  imports: [
    NgbModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    //FormsModule,
    //NgForm,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, ToastrService
    
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
