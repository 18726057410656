<div class="content-wrapper">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h3>Contract</h3>
        </div>
        <!-- <div class="col-sm-6">
          <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">Jobpost</li>
          </ol>
        </div> -->
      </div>
    </div>
  </div>
  <section class="content">
    <div class="col-12">
      <div class="row">
        <div class="col-sm-6  col-md-4" *ngFor="let item of contractlist">
          <div class="card ">
            <div class="card-title">
              <div class="row">
                <div class="col-11">
                  <h5>{{item.contractTitle}}</h5>
                </div>
                <div class="col-1">
                  <i class="fa fa-times float-right " style="cursor: pointer;    color: #b3aeae;
                    " title="Delete Contract" *ngIf="item.status==='waitingforpharmacist' || item.status==='active'"
                    (click)="closethecard(item.contractReferenceId)" aria-hidden="true"></i>
                </div>
              </div>
            </div>
            <div class="card-body">

              <div class="d-flex pt-2">
                <div *ngIf="item.profileImageUrl; else noImageTemplate">
                  <img src="{{s3_arn}}{{item.profileImageUrl}}" width="70px "
                    height="70px" style="border-radius: 50%;" />
                </div>

                <ng-template #noImageTemplate>
                  <img src="../../../assets/images/placeholder-image.png" width="70px " height="70px"
                    style="border-radius: 50%;" />
                </ng-template>

                <div class="pl-2">
                  <h6 class="contractname" (click)='gopharmacistprofile(item.pharmacistReferenceId)'>
                    <b>{{item.pharmacistName}}</b>
                  </h6>
                  <p>Date :&nbsp;&nbsp;&nbsp;{{item.finalizedDateTime | date: "MMM d, y" : 'GMT' }}</p>
                  <p style="    margin-top: -10px;
                    ">Time:&nbsp;&nbsp;{{item.startDateTime | date : 'shortTime' : 'GMT'}} to {{item.endDateTime | date
                    :
                    'shortTime': 'GMT'}}</p>

                  <p style="margin-top: -10px;">Budget:&nbsp;&nbsp;${{item.budget}}<span
                      *ngIf="item.paymentType === 10; else paymentTypeTemplate">
                      /hr
                    </span>
                    <ng-template #paymentTypeTemplate>
                      Fixed
                    </ng-template>
                  </p>
                </div>
              </div>
              <div class="text-center pt-4">
                <h6>Finalized Amount &nbsp;&nbsp;&nbsp;&nbsp; <span
                    style="font-size: 18px;">${{item.finalizedAmount}}</span> </h6>
                <div class="pt-3">
                  <!-- <p (click)='item.status?:' [ngClass]="[item.status ? 'class1' : 'waitingforpharmacist',  item.status ? 'class1' : 'active', (item.status && (menu1 || menu2)) ? 'class2' : 'active']">{{item.status}}</p> -->

                  <div *ngIf="item.startDateTime >= todaysDate; else expiredContractTemplate">
                    <p *ngIf="item.status==='waitingforpharmacist' || item.contractStatus==='waitingforpharmacist'" class="text-danger" style="font-size: 18px;">
                      <b>Waiting For Pharmacist</b>
                    </p>
                    <p *ngIf="item.status==='active' && item.contractStatus!='waitingforpharmacist'" class="text-success" style="font-size: 18px;"><b>Active</b></p>
                    <p *ngIf="item.status==='completed'" class="text-success" style="font-size: 18px;"><b>Completed</b></p>
                  </div>

                  <ng-template #expiredContractTemplate>
                    <p class="text-danger" style="font-size: 18px;"><b>Expired</b></p>
                  </ng-template>

                  <div class="row">
                    <div class="col-3"></div>
                    <div class="col-6"> <button class="btn btn-sm btn-primary " style="font-size: 20px;width: 100%;"
                        [ngClass]="
                          item.ratings || item.description
                            ? '  black'
                            : 'success'
                        " *ngIf="item.status==='completed'"
                        (click)='modalHideShow(item.contractId,item.pharmacistId,item.pharmacistName)'>Write
                        review</button>

                      <button class="btn btn-sm btn-primary " *ngIf="item.status==='completed'"
                        style="font-size: 18px;width: 100%;" [ngClass]="
                          item.ratings || item.description
                            ? '  success'
                            : 'black'
                        "
                        (click)='modalHideShhhow(item.contractId,item.pharmacistId,item.pharmacistName,item.description,item.ratings)'>View
                        review</button>
                    </div>
                    <div class="col-3"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card p-3" style="width: 100%;
        height: 550px;" *ngIf='contractlist.length === 0'>
          <div class="card-body">
            <div class=" text-center">
              <!-- <p class="text-center" style="font-size: 25px;">
                No Records
              </p> -->
              <img src="../../../assets/images/nodata.jpg" height="350px" />
              <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories - www.freepik.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf='contractlist.length !== 0' class="pb-3 pt-3">
        <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" (pageChange)="reloadDataa()" [pageSize]="10"
          [maxSize]="4" [boundaryLinks]="true"></ngb-pagination>
      </div>
    </div>
  </section>
</div>


<!-- write review -->

<div class="modal" id="myModal" [ngClass]="modalShow ? 'success' : 'black'" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="col-12 " style="border-bottom: 1px solid rgb(206, 202, 202);">
        <div class="row pt-2" style="width: 100%;">
          <div class="col-8">
            <h4 class="modal-title ">Write Review</h4>
          </div>
          <div class="col-4">
            <i class="fa fa-times timesss float-right " (click)="modalHideShoww()" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <div class="card-body">
          <form [formGroup]="formVar" (ngSubmit)="onSubmit()">
            <div class="card-body">
              <div class="col-sm-12">
                <div class="text-center pb-1" style="margin-top: -50px;">
                  <img src="../../../../assets/images/female-pharmacist-home.jpg" width="50px " height="50px"
                    style="border-radius: 50%;" />
                  <h6 style="padding-top: 7px;">{{pharmacistName}}</h6>
                </div>
                <div class="pb-1" class="star">
                  <div class="d-flex">
                    <i class="fa fa-star writeratingstar" aria-hidden="true"
                      [ngClass]="(countt ==1 || countt ==2||countt ==3||countt ==4||countt ==5)?'display':'notdispl'"
                      value='1' (click)='count(1)'></i>
                    <i class="fa fa-star writeratingstar" aria-hidden="true"
                      [ngClass]="(countt ==2 || countt ==3 || countt ==4|| countt ==5)?'display':'notdispl'" value='2'
                      (click)='count(2)'></i>
                    <i class="fa fa-star writeratingstar" aria-hidden="true"
                      [ngClass]="(countt ==3 || countt ==4 ||countt ==5)?'display':'notdispl'" value='3'
                      (click)='count(3)'></i>
                    <i class="fa fa-star writeratingstar" aria-hidden="true"
                      [ngClass]="(countt ==4 || countt ==5 )?'display':'notdispl'" value='4' (click)='count(4)'></i>
                    <i class="fa fa-star writeratingstar" aria-hidden="true" [ngClass]="countt ==5?'display':'notdispl'"
                      value='5' (click)='count(5)'></i>
                  </div>
                </div>
                <div class="form-group">
                  <textarea type="text" class="form-control" formControlName="description" [ngClass]="{
                        'is-invalid': submitted && f.description.errors
                      }"></textarea>
                  <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
                    <div *ngIf="f.description.errors.required">
                      description is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
            <div class="text-center">
              <button type="submit" class="btn">Save</button>

              <!-- <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  (click)="modalHideShoww()"
                >
                  Close</button> -->
              <br />
            </div>
          </form>
        </div>
        <!-- /.card-body -->
      </div>
    </div>
  </div>
</div>


<!-- view review -->
<div class="modal" id="myModal" [ngClass]="modalShoow ? 'success' : 'black'" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="col-12" style="    border-bottom: 1px solid #d8d4d4;">
        <div class="row pt-2">
          <div class="col-8">
            <h4 class="modal-title ">View Review</h4>
          </div>
          <div class="col-4">
            <i class="fa fa-times timesss float-right " (click)="modalHideShhoww()" aria-hidden="true"></i>
          </div>
        </div>
      </div>
      <div class="modal-body">
        <form>
          <div class="card-body">
            <div class="card-body">
              <div class="col-sm-12">
                <div class="text-center pb-1" style="margin-top: -50px;">
                  <img src="../../../../assets/images/female-pharmacist-home.jpg" width="50px " height="50px"
                    style="border-radius: 50%;" />
                  <h6 style="padding-top: 7px;">{{pharmacistName}}</h6>
                </div>
                <div>
                  <div class="d-flex showstar" *ngIf='this.rating!==null'>
                    <div *ngFor='let in of counter(toNumber(this.rating)) ;let i = index'>
                      <i class="fa fa-star" aria-hidden="true" style="color: #ffc107;"></i>
                    </div>
                    <div *ngFor='let in of counter(5-toNumber(this.rating)) ;let i = index'><i class="fa fa-star-o"
                        aria-hidden="true"></i>
                    </div>
                  </div>
                  <p style="text-align: center;"><b>Description</b> : {{descriptinn}}</p>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
            <!-- <div class="">
                  <button
                  type="button"
                  class="btn btn-danger"
                  data-dismiss="modal"
                  (click)="modalHideShhoww()"
                >
                  Close</button
                ><br />
              </div> -->
          </div>
          <!-- /.card-body -->
        </form>
      </div>
    </div>
  </div>
</div>
