import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-superadmin-auth-layout',
  templateUrl: './superadmin-auth-layout.component.html',
  styleUrls: ['./superadmin-auth-layout.component.css']
})
export class SuperadminAuthLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
