'use strict';
import { Component, OnInit, AfterViewChecked, ElementRef, ViewChild } from '@angular/core';
import io from 'socket.io-client';
import * as $ from "jquery";
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { PharmacyService } from '../SuperAdmin/pharmacy/pharmacy.service';
import { UserService } from '../user/user.service';
import { first } from 'rxjs/operators';
import { JSONPBackend } from '@angular/http';
import * as moment from 'moment';
import { AppSettings } from 'src/app/app.settings';

const SOCKET_ENDPOINT = AppSettings.apiurls.CHAT_SOCKET;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})

export class ChatComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;

  page = 1;
  referenceid: any
  message: String;
  sendMessagePlaceholder: String;
  socket: any;
  chatUsers: [];
  typingtimeout: any;
  currentUser: any;
  sendTo: String;
  pharmacyUserId: any;
  pharmacistId: any;
  messageList: any;
  senderImage: any;
  pharmacistName: any;
  public s3_arn: any;

  constructor(
    private pharmacyService: PharmacyService,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) {
    this.s3_arn = AppSettings.apiurls.S3_ARN;
    this.message = "";
    this.sendMessagePlaceholder = "Type a message";
    this.chatUsers = [];
    this.typingtimeout = "";
    this.sendTo = "";
    this.senderImage = "";
    this.pharmacistName = "";
    this.socket = io(SOCKET_ENDPOINT, {path: '/message/socket.io'});
    //this.socket = io(SOCKET_ENDPOINT);
  }

  async ngOnInit() {
    this.setupSocketConnection();
    this.currentUser = localStorage.getItem('currentUser');
    let pharmacyUserInfo = JSON.parse(localStorage.getItem('pharmacyUserId'));
    this.pharmacyUserId = pharmacyUserInfo.data.id;
    let currentUserData = JSON.parse(this.currentUser);
    let currentUserInfo = currentUserData.data;
    this.referenceid = currentUserInfo.referenceId;

    await this.addUser();
    this.getChatUsers(this.page);
    this.scrollToBottom();
  }

  /*setupSocketConnection() {
    this.socket = io(SOCKET_ENDPOINT);
  }*/

  addUser = async () => {
    //Auto select user
    let autoSelectUser = history.state.data || "";
    if (autoSelectUser != "") {
      //Send message
      this.message = "Hi";
      this.messageList = [];
      this.selectSendTo({
        pharmacistId: autoSelectUser.pharmacistId,
        pharmacistReferenceId: autoSelectUser.pharmacistReferenceId,
        pharmacistProfileImageUrl: null
      })
      await this.SendMessage();
      setTimeout(() => {
        window.location.reload();
      }, 2000);

    }
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }
  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  SendMessage() {
    let messageInfo = {
      "jobpostId": 1,  // not in use
      "referenceId": this.sendTo,
      "pharmacyUserId": this.pharmacyUserId,
      "pharmacistId": this.pharmacistId,
      "message": this.message,
      "sender": 1,
      //"pharmacistProfileImageUrl": this.senderImage,
      //"pharmacistName": this.pharmacistName,
      "createdAt": moment().format()
    };
    let messages = this.messageList;

    messages.push(messageInfo);
    this.messageList = messages;
    this.socket.emit('message', messageInfo);
    /*let fromMessage = `<div class="row media w-100 mb-3 pull-right">
                        <img src="https://angular.io/assets/images/logos/angular/angular.svg" alt="user" width="50" class="rounded-circle" style="height: 50px">
                        <div class="media-body ml-3">
                          <div class="bg-light rounded py-2 px-3 mb-2">
                            <p class="text-small mb-0 text-muted">`+ this.message + `</p>
                          </div>
                          <p class="small text-muted">`+Date.now()+`</p>
                        </div>
                      </div>`;*/

    //$("#messages").append(fromMessage);
    this.message = '';
    //this.sendMessageToDatabase(messageInfo);
  }

  selectSendTo = (user) => {
    this.sendTo = user.pharmacistReferenceId;
    this.senderImage = user.pharmacistProfileImageUrl;
    this.pharmacistName = user.pharmacistName;
    this.pharmacistId = user.pharmacistId;
    this.getChatDetails(user.pharmacistId)
  }

  UserType(value: String) {
    this.message = value;
    this.userIsTyping();
  }

  setupSocketConnection() {

    this.socket.on("connect", () => {
      this.socket.emit('connect-me', this.referenceid);
    });

    this.socket.on('connected-me', (data: any) => {
      console.log('me connected!!!')
      //this.chatUsers = JSON.parse(data);
      let tempChatUsers = this.chatUsers.map((user) => {
        let container = {};
        container = user;
        if (user['referenceId'] == data.referenceId) {
          container['socketId'] = data.socketId;
        }
        return container;
      });
    });

    this.socket.on('message', (data: string) => {
      console.log('message recieve', data)
      if (data) {
        let toMessage = `<div class="media w-100 mb-3">
                        <div class="media ml-auto mb-3">
                          <div class="media-body">
                            <div class="bg-primary rounded py-2 px-3 mb-2">
                              <p class="text-small mb-0 text-white">`+ data + `</p>
                            </div>
                            <p class="small text-muted">12:00 PM | Aug 13</p>
                          </div>
                          </div>
                        </div>`;
        //$("#messages").append(toMessage);
        this.messageList.push(data);
      }
    });

    //Typing
    this.socket.on('typing', (data: String) => {
      console.log('typing....')
      this.sendMessagePlaceholder = "Typing..";
    })

    this.socket.on('not-typing', (data: String) => {
      this.sendMessagePlaceholder = "Type a new message";
    })
  }

  userIsTyping() {
    let timeout;
    console.log('typing...', this.socket)
    this.socket.emit('typing', this.sendTo)
    
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.socket.emit('not-typing', this.sendTo);
    }, 2000);
  }
  //End Typing


  sendMessageToDatabase(message): void {
    this.spinner.show();
    this.pharmacyService

    .sendChat(message)
      .pipe(first())
      .subscribe(
        (data) => {
          this.spinner.hide();
        },
        (error) => {
          console.log('#66Error Message: ', error);
        }
      );
  }

  getsubuserlist(referenceid, page): void {
    this.spinner.show();
    this.userService
      .Getsabuselist(referenceid, page)
      .pipe(first())
      .subscribe(
        (data) => {
          this.spinner.hide();
          if (data.data.listResult) {
            this.chatUsers = data.data.listResult;
          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
        }
      );
  }

  getChatUsers(page): void {
    this.spinner.show();
    this.pharmacyService
      .getChatUsers(this.pharmacyUserId, page)
      .pipe(first())
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.data) {
            this.chatUsers = res.data;

            //Select user
            if (this.sendTo == "" && res.data[0] != undefined) {
              let user = res.data[0];
              this.selectSendTo({
                pharmacistId: user.pharmacistId,
                pharmacistReferenceId: user.pharmacistReferenceId,
                senderImage: user.pharmacistProfileImageUrl
              })
            }
          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
        }
      );
  }

  getChatDetails(pharmacistId): void {
    this.spinner.show();
    this.pharmacyService
      .getChatDetails(pharmacistId, this.pharmacyUserId)
      .pipe(first())
      .subscribe(
        (res) => {
          this.spinner.hide();
          if (res.data) {
            //$("#messages").html('');
            this.messageList = res.data;
          }
        },
        (error) => {
          console.log('#66Error Message: ', error);
        }
      );
  }
}
