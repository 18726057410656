import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../../../app.settings';
import { Observable, throwError, from, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Headers, RequestOptions, Request, RequestMethod } from '@angular/http';
@Injectable({
  providedIn: 'root'
})
export class PharmacyService {

  constructor(private http: HttpClient) { }

   public getpharmacylist(filter,value,page): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.SUPERADMINPHARMACYLIST +
          `?filter=${filter}&filterValue=${value}&limit=10&offset=${(page - 1) * 10}`
      )
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(this.errorHandler)
      );
   }
  
   updatePharmacy(pharmacyData, pharmacyId) {
    return this.http
      .put<any>(AppSettings.apiurls.PHARMACYMEMBERSHIPBYID + pharmacyId, pharmacyData)
      .pipe(
        map((updatePharmacy) => {
          console.log(
            'Pharmacy User Data--------->',
            JSON.stringify(updatePharmacy)
          );
          return JSON.stringify(updatePharmacy);
        }),
        catchError(this.errorHandler)
      );
  }

   public getPharmacyJoblisting(pharmacyRefrenceId, page): Observable<any> {
    console.log(pharmacyRefrenceId);
    return this.http
      .get(
        AppSettings.apiurls.GETPHARMACYJOBLISTING +
          pharmacyRefrenceId +
          `/joblisting?limit=10&offset=${(page - 1) * 10}`
      )
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(this.errorHandler)
      );
   }

   updateJobPost(jobpostData, jobpostId) {
    console.log(jobpostId);
    console.log(AppSettings.apiurls.UPDATEJOBPOST + jobpostId);
    return this.http
      .put<any>(AppSettings.apiurls.UPDATEJOBPOST + jobpostId, jobpostData)
      .pipe(
        map((updateJobpost) => {
          console.log(
            'Pharmacy User Data--------->',
            JSON.stringify(updateJobpost)
          );
          return JSON.stringify(updateJobpost);
        }),
        catchError(this.errorHandler)
      );
  }
  
  public getpharmacydetaillist(pharmacyRefrenceId): Observable<any> {
    return this.http
      .get(
        AppSettings.apiurls.GETPHARMACYDETAILFORSUPERADMIN + pharmacyRefrenceId
      )
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(this.errorHandler)
      );
   }

  public getlistwallet(pharmacyRefrenceId, page): Observable<any> {
    console.log(pharmacyRefrenceId);
    return this.http
      .get(
        AppSettings.apiurls.PHARMACYTRASCATION +
        pharmacyRefrenceId +
          `?limit=10&offset=${(page - 1) * 10}`
      )
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(this.errorHandler)
      );
  }
  
  updateauth(data, authid) {
    console.log(authid);
    console.log(AppSettings.apiurls.GETAUTHUSER + authid);
    return this.http
      .put<any>(AppSettings.apiurls.GETAUTHUSER + authid, data)
      .pipe(
        map((updateauth) => {
          console.log(
            'Pharmacy User Data--------->',
            JSON.stringify(updateauth)
          );
          return JSON.stringify(updateauth);
        }),
        catchError(this.errorHandler)
      );
    }

    sendChat(data) {
      return this.http
        .post<any>(AppSettings.apiurls.SENDCHATMESSAGE, { ...data })
        .pipe(
          map((res) => {
            console.log(res)
            return res;
          }),
          catchError(this.errorHandler)
        );
    }


    public getChatUsers(pharmacyUserId, page): Observable<any> {
      console.log('pharmacyUserId', pharmacyUserId);
      return this.http
        .get(
          AppSettings.apiurls.LISTCHATUSERS + `?pharmacyUserId=` 
          + pharmacyUserId
        )
        .pipe(
          map((res) => {
            console.log(res);
            return res;
          }),
          catchError(this.errorHandler)
        );
    }
    errorHandler(error: any) {
    console.log('Error--->', error);
    return throwError(error);
  }

  public getChatDetails(pharmacistId, pharmacyUserId): Observable<any> {
    console.log(pharmacistId);
    return this.http
      .get(
        AppSettings.apiurls.USERCHATDETAILS + `?pharmacistId=` 
        + pharmacistId + `&pharmacyUserId=` + pharmacyUserId
      )
      .pipe(
        map((res) => {
          console.log(res);
          return res;
        }),
        catchError(this.errorHandler)
      );
  }

}
