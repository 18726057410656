<div class="content-wrapper">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-0">
        <div class="col-sm-6">
          <h3>Hire</h3>
        </div>
      </div>
    </div>
  </div>
  <section class="content">
    <div class="col-12">
      <div class="row">
        <div class="col-md-4 col-sm-6" *ngFor="let item of hielist">
          <div class="card p-0q">
            <div class="card-body">
              <div class="text-center">
                <div *ngIf="item.profileImageUrl; else noImageTemplate">
                  <img src="{{s3_arn}}{{item.profileImageUrl}}" width="70px " height="70px"
                    style="border-radius: 50%;" />
                </div>
                <ng-template #noImageTemplate>
                  <img src="../../../assets/images/placeholder-image.png" width="70px " height="70px"
                    style="border-radius: 50%;" />
                </ng-template>
                <div class="pl-3 pt-2 text-center" class="hireparmacistname"
                  (click)='opencard(item.proposalId,item.proposalReferenceId)'>
                  <h5>{{item.pharmacistName}}</h5>
                </div>
              </div>
              <div class='row pt-3'>
                <div class="col-9">
                  <p>Date <span class="pl-3">{{item.finalizedDateTime | date:'mediumDate': 'GMT'}}</span></p>
                  <p>Start Time <span class="pl-3">{{item.startDateTime | date:'mediumDate': 'GMT'}}
                      {{item.startDateTime | date:'shortTime': 'GMT'}} </span> </p>
                  <p>End Time <span class="pl-3">{{item.endDateTime | date:'mediumDate': 'GMT'}} {{item.endDateTime |
                      date:'shortTime': 'GMT'}} </span> </p>
                  <p>Duration <span class="pl-3">{{item.expectedhours }}</span> </p>
                </div>
                <div class="col-3">
                  <p class="text-white text-center pb-1" style="background-color: #00A3FF;border-radius: 6px;">
                    ${{item.finalizedAmount}}
                    <span *ngIf="item.paymentType === 10; else paymentTypeTemplate">
                      Per hour
                    </span>
                    <ng-template #paymentTypeTemplate>
                      Fixed
                    </ng-template>
                </div>
              </div>
              <div class="text-center mt-3">
                <div class="row ">
                  <!-- <div class=""></div> -->
                  <div class="col-1"></div>
                  <div class="col-10">
                    <p *ngIf="item.status==='active' && item.jobPostStatus != 'completed'" class="text-primary staaus"
                      style="cursor: pointer;"
                      (click)='modalHideShoww(item.pharmacistName , item.finalizedDateTime,item.finalizedAmount,item.contractReferenceId,item.contractTitle,item.expectedhours)'>
                      <b>Cancel Schedule</b>
                    </p>

                  </div>
                  <div class="col-1"></div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <br />
      <div class="card " style="width: 100%;
        height: 550px;" *ngIf='hielist.length === 0'>
        <div class="card-body">
          <div class=" text-center">
            <!-- <p class="text-center" style="font-size: 25px;">
                No Records
              </p> -->
            <img src="../../../assets/images/nodata.jpg" height="350px" />
            <div><a href='https://www.freepik.com/vectors/data'>Data vector created by stories - www.freepik.com</a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf='hielist.length !== 0' class="pb-4">
        <ngb-pagination [collectionSize]="collectionsize" [(page)]="page" (pageChange)="reloadDataa()" [pageSize]="9"
          [maxSize]="4" [boundaryLinks]="true"></ngb-pagination>
      </div>
    </div>
  </section>
</div>


<div class="modal" id="myModal" [ngClass]="modalShow ? 'success' : 'black'" role="dialog" data-backdrop="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="">
        <div class="pl-3 pt-2">
          <div>
            <h4 (click)="modalHide()"><i class="fa fa-angle-left px-2 py-1" aria-hidden="true" title="Go Back" style="background: #C4C4C4;cursor: pointer; 
             border-radius: 8px;"></i>
            </h4>
          </div>
        </div>
      </div>
      <div class="modal-body" style="    margin-top: -25px;">
        <div>
          <div class="text-center"><img src="../../../../assets/images/female-pharmacist-home.jpg" width="70px "
              height="70px" style="border-radius: 50%;" />
            <p style="font-size: 18px;"><b>{{name}}</b></p>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-8 px-4">
                <p>Date of Hire &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{datetime | date: "MMM d, y"}}</p>
                <p>Time of Hire &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{datetime | date: "shortTime"}}</p>
                <p>Approximate Cost &nbsp;&nbsp;&nbsp;&nbsp; ${{finalcost}}</p>

              </div>
              <div class="col-4 float-right">
                <div style="background: #00A3FF;
               border-radius: 6px;">
                  <p class="text-white text-center" style="font-size: 20px;"><b>{{expectedtime}} H</b></p>
                  <p class="text-white text-center" style="margin-top: -5px;">Total hours</p>
                </div>
              </div>
            </div>


            <!-- <div class="text-center mt-4">
              <p style="font-size: 18px;">Cancellation Charge &nbsp;&nbsp;<span style="background: #C4C4C4; padding: 7px;
                border-radius: 8px;">$5</span></p>
            </div> -->
            <!-- /.card-body -->
            <div class="row">
              <div class="col-3"></div>
              <div class="col-6">
                <button type="submit" class="btn bttnn" (click)='submit()'>Cancel Schedule</button>
              </div>
              <div class="col-3"></div>
            </div>
          </div>
          <!-- <button style="width: 75px;" type="button" class="btn btn-danger" data-dismiss="modal"
                (click)="modalHideShoww()">
                Close</button><br /> -->
        </div>
      </div>
    </div>
  </div>
</div>