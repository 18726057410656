import { parseHostBindings } from '@angular/compiler';

export const role = {
  SYSTEMADMIN: 1,
  PHARMACY: 2,
  PHARMACIST: 3,
  PHARMACYSUBUSER: 4,
  SUPERADMINSUBUSER: 5,
};

export const status = {
  PENDING: 'pending',
  ACTIVE: 'active',
  DECLINED: 'declined',
};

export const expertise = [
  { key: 'Retail', Value: 1 },
  { key: 'Hospital', Value: 2 },
  { key: 'Institutional', Value: 3 },
  { key: 'Long Term Care', Value: 4 },
  { key: 'Mail Order', Value: 5 },
];

export const additionalSkills = [
  { key: 'Vaccination ', Value: 1 },
  { key: 'Compounding', Value: 2 },
  { key: 'MTM', Value: 3 },
];

export const pharmacyOperation = [
  { key: 'Beginner ', Value: 1 },
  { key: 'Intermediate', Value: 2 },
  { key: 'Advanced', Value: 3 },
];

export const softwareSkills = [
  { key: 'Cerner Retail Pharmacy ', Value: 1 },
  { key: 'McKesson Pharmacy Systems', Value: 2 },
  { key: 'PrimeRx', Value: 3 },
  { key: 'Winpharm', Value: 4 },
  { key: 'NRx by QS1', Value: 5 },
  { key: 'RX30 Pharmacy System', Value: 6 },
  { key: 'Computer-Rx', Value: 7 },
  { key: 'PDX Classic', Value: 8 },
  { key: 'AbacusRx', Value: 9 },
  { key: 'BestRx', Value: 10 },
  { key: 'FrameworkLTC', Value: 11 },
  { key: 'ScriptPro', Value: 12 },
  { key: 'Chetu Pharmacy', Value: 13 },
  { key: 'PROScript 2000', Value: 14 },
  { key: 'SuiteRx', Value: 15 },
  { key: 'Advanced Rx', Value: 16 },
  { key: 'GuardianRx', Value: 17 },
  { key: 'PKon Rx by SRS ', Value: 18 },
  { key: 'RxMaster', Value: 19 },
  { key: 'RXQ by Liberty software', Value: 20 },
  { key: 'SharpRx by QS!', Value: 21 },
  { key: 'hCue Pharmacy', Value: 22 },
  { key: 'NewLeafRx', Value: 23 },
  { key: 'Asembia1', Value: 24 },
  { key: 'RMS', Value: 25 },
  { key: 'PioneerRx', Value: 26 },
  { key: 'WinRx', Value: 27 },
  { key: 'VIP Pharmacy', Value: 28 },
  { key: 'Pharmaserv', Value: 29 },
  { key: 'RxGenesys Suite', Value: 30 },
  { key: 'RxAxis Suite', Value: 31 },
];

export const jobVisibility = [
  { key: 'Anyone ', Value: 1 },
//   { key: 'Only Hire Talent', Value: 2 },
  { key: 'Invite Only', Value: 3 },
];

export const PaymentType = [
  { key: 'Pay by Hour ', Value: 10 },
  { key: 'Fixed Price', Value: 100 },
];
export const Section = [
  { key: 'Jobs', Value: false, },
  { key: 'Hire', Value: false },
  // { key: 'Invitation', Value: true },
  { key: 'Message', Value: false },
  { key: 'Wallet', Value: false },
  { key: 'Contracts', Value: false },

];
export const Superadminsection = [
  { key: 'Pharmacy', Value: false, },
  { key: 'Pharmacist', Value: false },
  { key: 'Voucher', Value: false },
  { key: 'CredentialRequest', Value: false },
  { key: 'User', Value: false },
  { key: 'Membership', Value: false },


];
export const Paymentmethod = [
  { key: 'Credit/Debit', Value: 'Credit/Debit' },
  { key: 'Connect Bank', Value:'Connect Bank'},
]

export const timezones = [
  {
     "value":"Pacific/Midway",
     "label":"(GMT-11:00) Midway Island"
  },
  {
     "value":"US/Samoa",
     "label":"(GMT-11:00) Samoa"
  },
  {
     "value":"US/Hawaii",
     "label":"(GMT-10:00) Hawaii"
  },
  {
     "value":"US/Alaska",
     "label":"(GMT-09:00) Alaska"
  },
  {
     "value":"US/Pacific",
     "label":"(GMT-08:00) Pacific Time (US &amp; Canada)"
  },
  {
     "value":"America/Tijuana",
     "label":"(GMT-08:00) Tijuana"
  },
  {
     "value":"US/Arizona",
     "label":"(GMT-07:00) Arizona"
  },
  {
     "value":"US/Mountain",
     "label":"(GMT-07:00) Mountain Time (US &amp; Canada)"
  },
  {
     "value":"America/Chihuahua",
     "label":"(GMT-07:00) Chihuahua"
  },
  {
     "value":"America/Mazatlan",
     "label":"(GMT-07:00) Mazatlan"
  },
  {
     "value":"America/Mexico_City",
     "label":"(GMT-06:00) Mexico City"
  },
  {
     "value":"America/Monterrey",
     "label":"(GMT-06:00) Monterrey"
  },
  {
     "value":"Canada/Saskatchewan",
     "label":"(GMT-06:00) Saskatchewan"
  },
  {
     "value":"US/Central",
     "label":"(GMT-06:00) Central Time (US &amp; Canada)"
  },
  {
     "value":"America/New_York",
     "label":"(GMT-05:00) Eastern Time (US &amp; Canada)"
  },
  {
     "value":"US/East-Indiana",
     "label":"(GMT-05:00) Indiana (East)"
  },
  {
     "value":"America/Bogota",
     "label":"(GMT-05:00) Bogota"
  },
  {
     "value":"America/Lima",
     "label":"(GMT-05:00) Lima"
  },
  {
     "value":"America/Caracas",
     "label":"(GMT-04:30) Caracas"
  },
  {
     "value":"Canada/Atlantic",
     "label":"(GMT-04:00) Atlantic Time (Canada)"
  },
  {
     "value":"America/La_Paz",
     "label":"(GMT-04:00) La Paz"
  },
  {
     "value":"America/Santiago",
     "label":"(GMT-04:00) Santiago"
  },
  {
     "value":"Canada/Newfoundland",
     "label":"(GMT-03:30) Newfoundland"
  },
  {
     "value":"America/Buenos_Aires",
     "label":"(GMT-03:00) Buenos Aires"
  },
  {
     "value":"Greenland",
     "label":"(GMT-03:00) Greenland"
  },
  {
     "value":"Atlantic/Stanley",
     "label":"(GMT-02:00) Stanley"
  },
  {
     "value":"Atlantic/Azores",
     "label":"(GMT-01:00) Azores"
  },
  {
     "value":"Atlantic/Cape_Verde",
     "label":"(GMT-01:00) Cape Verde Is."
  },
  {
     "value":"Africa/Casablanca",
     "label":"(GMT) Casablanca"
  },
  {
     "value":"Europe/Dublin",
     "label":"(GMT) Dublin"
  },
  {
     "value":"Europe/Lisbon",
     "label":"(GMT) Lisbon"
  },
  {
     "value":"Europe/London",
     "label":"(GMT) London"
  },
  {
     "value":"Africa/Monrovia",
     "label":"(GMT) Monrovia"
  },
  {
     "value":"Europe/Amsterdam",
     "label":"(GMT+01:00) Amsterdam"
  },
  {
     "value":"Europe/Belgrade",
     "label":"(GMT+01:00) Belgrade"
  },
  {
     "value":"Europe/Berlin",
     "label":"(GMT+01:00) Berlin"
  },
  {
     "value":"Europe/Bratislava",
     "label":"(GMT+01:00) Bratislava"
  },
  {
     "value":"Europe/Brussels",
     "label":"(GMT+01:00) Brussels"
  },
  {
     "value":"Europe/Budapest",
     "label":"(GMT+01:00) Budapest"
  },
  {
     "value":"Europe/Copenhagen",
     "label":"(GMT+01:00) Copenhagen"
  },
  {
     "value":"Europe/Ljubljana",
     "label":"(GMT+01:00) Ljubljana"
  },
  {
     "value":"Europe/Madrid",
     "label":"(GMT+01:00) Madrid"
  },
  {
     "value":"Europe/Paris",
     "label":"(GMT+01:00) Paris"
  },
  {
     "value":"Europe/Prague",
     "label":"(GMT+01:00) Prague"
  },
  {
     "value":"Europe/Rome",
     "label":"(GMT+01:00) Rome"
  },
  {
     "value":"Europe/Sarajevo",
     "label":"(GMT+01:00) Sarajevo"
  },
  {
     "value":"Europe/Skopje",
     "label":"(GMT+01:00) Skopje"
  },
  {
     "value":"Europe/Stockholm",
     "label":"(GMT+01:00) Stockholm"
  },
  {
     "value":"Europe/Vienna",
     "label":"(GMT+01:00) Vienna"
  },
  {
     "value":"Europe/Warsaw",
     "label":"(GMT+01:00) Warsaw"
  },
  {
     "value":"Europe/Zagreb",
     "label":"(GMT+01:00) Zagreb"
  },
  {
     "value":"Europe/Athens",
     "label":"(GMT+02:00) Athens"
  },
  {
     "value":"Europe/Bucharest",
     "label":"(GMT+02:00) Bucharest"
  },
  {
     "value":"Africa/Cairo",
     "label":"(GMT+02:00) Cairo"
  },
  {
     "value":"Africa/Harare",
     "label":"(GMT+02:00) Harare"
  },
  {
     "value":"Europe/Helsinki",
     "label":"(GMT+02:00) Helsinki"
  },
  {
     "value":"Europe/Istanbul",
     "label":"(GMT+02:00) Istanbul"
  },
  {
     "value":"Asia/Jerusalem",
     "label":"(GMT+02:00) Jerusalem"
  },
  {
     "value":"Europe/Kiev",
     "label":"(GMT+02:00) Kyiv"
  },
  {
     "value":"Europe/Minsk",
     "label":"(GMT+02:00) Minsk"
  },
  {
     "value":"Europe/Riga",
     "label":"(GMT+02:00) Riga"
  },
  {
     "value":"Europe/Sofia",
     "label":"(GMT+02:00) Sofia"
  },
  {
     "value":"Europe/Tallinn",
     "label":"(GMT+02:00) Tallinn"
  },
  {
     "value":"Europe/Vilnius",
     "label":"(GMT+02:00) Vilnius"
  },
  {
     "value":"Asia/Baghdad",
     "label":"(GMT+03:00) Baghdad"
  },
  {
     "value":"Asia/Kuwait",
     "label":"(GMT+03:00) Kuwait"
  },
  {
     "value":"Africa/Nairobi",
     "label":"(GMT+03:00) Nairobi"
  },
  {
     "value":"Asia/Riyadh",
     "label":"(GMT+03:00) Riyadh"
  },
  {
     "value":"Europe/Moscow",
     "label":"(GMT+03:00) Moscow"
  },
  {
     "value":"Asia/Tehran",
     "label":"(GMT+03:30) Tehran"
  },
  {
     "value":"Asia/Baku",
     "label":"(GMT+04:00) Baku"
  },
  {
     "value":"Europe/Volgograd",
     "label":"(GMT+04:00) Volgograd"
  },
  {
     "value":"Asia/Muscat",
     "label":"(GMT+04:00) Muscat"
  },
  {
     "value":"Asia/Tbilisi",
     "label":"(GMT+04:00) Tbilisi"
  },
  {
     "value":"Asia/Yerevan",
     "label":"(GMT+04:00) Yerevan"
  },
  {
     "value":"Asia/Kabul",
     "label":"(GMT+04:30) Kabul"
  },
  {
     "value":"Asia/Karachi",
     "label":"(GMT+05:00) Karachi"
  },
  {
     "value":"Asia/Tashkent",
     "label":"(GMT+05:00) Tashkent"
  },
  {
     "value":"Asia/Calcutta",
     "label":"(GMT+05:30) Kolkata"
  },
  {
     "value":"Asia/Kathmandu",
     "label":"(GMT+05:45) Kathmandu"
  },
  {
     "value":"Asia/Yekaterinburg",
     "label":"(GMT+06:00) Ekaterinburg"
  },
  {
     "value":"Asia/Almaty",
     "label":"(GMT+06:00) Almaty"
  },
  {
     "value":"Asia/Dhaka",
     "label":"(GMT+06:00) Dhaka"
  },
  {
     "value":"Asia/Novosibirsk",
     "label":"(GMT+07:00) Novosibirsk"
  },
  {
     "value":"Asia/Bangkok",
     "label":"(GMT+07:00) Bangkok"
  },
  {
     "value":"Asia/Jakarta",
     "label":"(GMT+07:00) Jakarta"
  },
  {
     "value":"Asia/Krasnoyarsk",
     "label":"(GMT+08:00) Krasnoyarsk"
  },
  {
     "value":"Asia/Chongqing",
     "label":"(GMT+08:00) Chongqing"
  },
  {
     "value":"Asia/Hong_Kong",
     "label":"(GMT+08:00) Hong Kong"
  },
  {
     "value":"Asia/Kuala_Lumpur",
     "label":"(GMT+08:00) Kuala Lumpur"
  },
  {
     "value":"Australia/Perth",
     "label":"(GMT+08:00) Perth"
  },
  {
     "value":"Asia/Singapore",
     "label":"(GMT+08:00) Singapore"
  },
  {
     "value":"Asia/Taipei",
     "label":"(GMT+08:00) Taipei"
  },
  {
     "value":"Asia/Ulaanbaatar",
     "label":"(GMT+08:00) Ulaan Bataar"
  },
  {
     "value":"Asia/Urumqi",
     "label":"(GMT+08:00) Urumqi"
  },
  {
     "value":"Asia/Irkutsk",
     "label":"(GMT+09:00) Irkutsk"
  },
  {
     "value":"Asia/Seoul",
     "label":"(GMT+09:00) Seoul"
  },
  {
     "value":"Asia/Tokyo",
     "label":"(GMT+09:00) Tokyo"
  },
  {
     "value":"Australia/Adelaide",
     "label":"(GMT+09:30) Adelaide"
  },
  {
     "value":"Australia/Darwin",
     "label":"(GMT+09:30) Darwin"
  },
  {
     "value":"Asia/Yakutsk",
     "label":"(GMT+10:00) Yakutsk"
  },
  {
     "value":"Australia/Brisbane",
     "label":"(GMT+10:00) Brisbane"
  },
  {
     "value":"Australia/Canberra",
     "label":"(GMT+10:00) Canberra"
  },
  {
     "value":"Pacific/Guam",
     "label":"(GMT+10:00) Guam"
  },
  {
     "value":"Australia/Hobart",
     "label":"(GMT+10:00) Hobart"
  },
  {
     "value":"Australia/Melbourne",
     "label":"(GMT+10:00) Melbourne"
  },
  {
     "value":"Pacific/Port_Moresby",
     "label":"(GMT+10:00) Port Moresby"
  },
  {
     "value":"Australia/Sydney",
     "label":"(GMT+10:00) Sydney"
  },
  {
     "value":"Asia/Vladivostok",
     "label":"(GMT+11:00) Vladivostok"
  },
  {
     "value":"Asia/Magadan",
     "label":"(GMT+12:00) Magadan"
  },
  {
     "value":"Pacific/Auckland",
     "label":"(GMT+12:00) Auckland"
  },
  {
     "value":"Pacific/Fiji",
     "label":"(GMT+12:00) Fiji"
  }
]